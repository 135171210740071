import { useContext, useEffect, useState } from 'react'
import CardLogo from '../assets/img/card-logo.svg'
import ChevronDown from '../assets/img/icons/chevron-down-outline.svg'
import WalletAction1 from '../assets/img/icons/wallet-action-1.svg'
import WalletAction2 from '../assets/img/icons/wallet-action-2.svg'
import WalletAction3 from '../assets/img/icons/wallet-action-3.svg'

import TransactionsList from '../components/TransactionsList'
import axios from 'axios'
import { UserContext } from '../hooks/context'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import { CircularProgress } from '@mui/material'

function Wallet() {
  const { context, setContext } = useContext(UserContext)
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false)
  const [assets, setAssets] = useState<any[]>([])
  // const transactionTokenList = [
  //   {
  //     id: 1,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'done',
  //   },
  //   {
  //     id: 2,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'processed',
  //   },
  //   {
  //     id: 3,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'declined',
  //   },
  //   {
  //     id: 4,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'done',
  //   },
  //   {
  //     id: 5,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'done',
  //   },
  //   {
  //     id: 6,
  //     date: '27.05.24, 12:30',
  //     transaction: 'withdraw',
  //     token: 'usdt',
  //     network: 'trc-20',
  //     sum: 300000,
  //     price: 69587.0,
  //     status: 'processed',
  //   },
  // ]

  useEffect(() => {
    setLoadingAssets(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query Balances {
            balances {
              id
              amount
              default
              currency {
                id
                name
                code
                blockChain
              }
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          setAssets(result.data.data.balances)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingAssets(false))
  }, [context.accessToken, setContext])

  return (
    <section className="wallet">
      <div className="wallet__inner">
        <div className="wallet__top">
          <div className="wallet__balance">
            <div className="wallet__head">
              <h2 className="wallet__title">Balance</h2>
            </div>

            <div className="wallet__card">
              <img className="wallet__card-logo" width="100" height="21" src={CardLogo} alt="logo" />

              <div className="wallet__card-footer">
                <p className="wallet__card-label">Balance</p>

                <button type="button" className="wallet__card-count">
                  <span>
                    {assets.filter((asset) => asset.default)[0]?.amount.toFixed(2) ?? '-'}{' '}
                    {assets.filter((asset) => asset.default)[0]?.currency.code.toUpperCase() ?? '-'}
                  </span>

                  <img src={ChevronDown} alt="arrow" />
                </button>
              </div>
            </div>

            <div className="wallet__actions">
              <button type="button" className="wallet__action">
                <img className="wallet__action-icon" width="36" height="36" src={WalletAction1} alt="icon" />
                <span>Deposit</span>
              </button>

              <button type="button" className="wallet__action">
                <img className="wallet__action-icon" width="36" height="36" src={WalletAction2} alt="icon" />
                <span>Withdraw</span>
              </button>

              <button type="button" className="wallet__action">
                <img className="wallet__action-icon" width="36" height="36" src={WalletAction3} alt="icon" />
                <span>Swap</span>
              </button>
            </div>
          </div>

          <div className="wallet__assets">
            <div className="wallet__head">
              <h2 className="wallet__title">Assets</h2>
            </div>

            <div className="wallet__assets-table">
              <div className="table__container">
                {!loadingAssets ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Cryptocurrency</th>
                        <th>Sum</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assets
                        .sort((a) => (a.default ? -1 : 1))
                        .map((item) => (
                          <tr key={item.id}>
                            <td>
                              <div className="table__info">
                                <img
                                  width="24"
                                  height="24"
                                  className="token-icon"
                                  src={
                                    item.currency.code === 'usdc'
                                      ? `https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=032`
                                      : `https://cryptologos.cc/logos/${item.currency.name.toLowerCase()}-${item.currency.code.toLowerCase()}-logo.svg?v=032`
                                  }
                                  alt="token"
                                />

                                <div className="table__info-texts">
                                  <p className="table__info-name">
                                    {item.currency.name}
                                    <span className="table__info-address"> ({item.currency.code.toUpperCase()})</span>
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>{item.amount.toFixed(2)}</td>
                            <td>
                              {item.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <CircularProgress
                    sx={{
                      position: 'absolute',
                      top: '25%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#df87f1',
                      zIndex: 2,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="wallet__bottom">
          <TransactionsList />
        </div>
      </div>
    </section>
  )
}

export default Wallet
