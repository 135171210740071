import { useContext } from 'react'
import { CircularProgress } from '@mui/material'
import { UserContext } from '../hooks/context'
import TransactionsList from '../components/TransactionsList'

function History() {
  const { context } = useContext(UserContext)

  return context.currentUser ? (
    <TransactionsList filtersOn />
  ) : (
    <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
  )
}

export default History
