import Logo from '../assets/img/logo.svg'
import Mail from '../assets/img/icons/registration-mail.svg'
import Lock from '../assets/img/icons/registration-lock.svg'

import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import axios from 'axios'
import validator from 'validator'

function Registration() {
  const [acknolage, setAcknolage] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRep, setPasswordRep] = useState('')
  const [validationError, setValidationError] = useState<any>({
    password: '',
    passwordRep: '',
    email: '',
  })
  const navigate = useNavigate()

  const registerHandler = () => {
    axios
      .post('https://api.statepix.io/api/v1/auth/signup', {
        email,
        password,
      })
      .then((res) => {
        if (res.status === 201) {
          navigate(`/verification`, { state: { email } })
        }
      })
      .catch((err) => {
        console.log(err)
        alert(err.response?.data.message)
      })
  }

  return (
    <div className="wrapper wrapper--center wrapper--gradient">
      <div className="registration">
        <div className="registration__inner">
          <Link className="registration__logo" to="/home">
            <img className="registration__logo-img" width="131" height="28" src={Logo} alt="logo img" />
          </Link>

          <div className="registration__head">
            <h1 className="registration__title">Register at StatePix</h1>
            <p className="registration__text">
              A ipsum sagittis ultrices tristique dictum. Pharetra orci ac vivamus eleifend massa adipiscing.
            </p>
          </div>

          <div className="registration__main">
            <div className="registration__rows">
              <div className="registration__row">
                <label className="registration__label">email</label>

                <div className="registration__input-container">
                  <input
                    style={{ border: validationError.email ? '1px solid red' : '' }}
                    onChange={(e) => {
                      setEmail(e.target.value.trim())
                      validator.isEmail(e.target.value.trim()) && setValidationError({ ...validationError, email: '' })
                    }}
                    onBlur={() => {
                      if (!validator.isEmail(email)) {
                        setValidationError({ ...validationError, email: 'Invalid email' })
                      } else {
                        setValidationError({ ...validationError, email: '' })
                      }
                    }}
                    value={email}
                    className="registration__input"
                    type="email"
                    placeholder="john_johnson@gmail.com"
                  />
                  <img className="registration__input-icon" width="20" height="20" src={Mail} alt="icon" />
                </div>
                <label style={{ color: 'red' }} className="registration__label">
                  {validationError.email}
                </label>
              </div>

              <div className="registration__row">
                <label className="registration__label">PASSWORD</label>

                <div className="registration__input-container">
                  <input
                    onBlur={() => {
                      if (!validator.isStrongPassword(password)) {
                        setValidationError({
                          ...validationError,
                          password:
                            'Password must be at least 8 characters, contain one uppercase letter, one lowercase letter, one number and one special character',
                        })
                      } else {
                        setValidationError({ ...validationError, password: '' })
                      }
                    }}
                    style={{ border: validationError.password ? '1px solid red' : '' }}
                    onChange={(e) => {
                      setPassword(e.target.value.trim())
                      validator.isStrongPassword(e.target.value.trim()) &&
                        setValidationError({ ...validationError, password: '' })
                    }}
                    value={password}
                    className="registration__input"
                    type="password"
                    placeholder="********"
                  />
                  <img className="registration__input-icon" width="20" height="20" src={Lock} alt="icon" />
                </div>
                <label style={{ color: 'red' }} className="registration__label">
                  {validationError.password}
                </label>
              </div>

              <div className="registration__row">
                <label className="registration__label">REpeat PASSWORD</label>

                <div className="registration__input-container">
                  <input
                    style={{ border: validationError.passwordRep ? '1px solid red' : '' }}
                    onBlur={() => {
                      if (password !== passwordRep) {
                        setValidationError({ ...validationError, passwordRep: 'Passwords do not match' })
                      } else {
                        setValidationError({ ...validationError, passwordRep: '' })
                      }
                    }}
                    onChange={(e) => {
                      setPasswordRep(e.target.value.trim())
                      password === e.target.value.trim() && setValidationError({ ...validationError, passwordRep: '' })
                    }}
                    value={passwordRep}
                    className="registration__input"
                    type="password"
                    placeholder="********"
                  />
                  <img className="registration__input-icon" width="20" height="20" src={Lock} alt="icon" />
                </div>
                <label style={{ color: 'red' }} className="registration__label">
                  {validationError.passwordRep}
                </label>
              </div>

              <div className="registration__row">
                <div className="registration__policy">
                  <input
                    onChange={() => setAcknolage(!acknolage)}
                    checked={acknolage}
                    className="registration__policy-checkbox"
                    type="checkbox"
                    id="policy-checkbox"
                  />
                  <label className="registration__policy-label" htmlFor="policy-checkbox">
                    <div className="registration__policy-check"></div>
                    <p className="registration__policy-text">
                      I have read and agree to the Terms of Service and Privacy Policy
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="registration__footer">
            <button
              disabled={
                !acknolage ||
                !validator.isEmail(email) ||
                !validator.isStrongPassword(password) ||
                !passwordRep ||
                password !== passwordRep
              }
              className={`registration__btn btn ${
                !acknolage ||
                !validator.isEmail(email) ||
                !validator.isStrongPassword(password) ||
                !passwordRep ||
                password !== passwordRep
                  ? 'disabled'
                  : ''
              }`}
              onClick={registerHandler}
            >
              REGISTER
            </button>

            <div className="registration__footer-row">
              <p className="registration__footer-text">Already have an account? </p>
              <Link className="registration__footer-link" to="/login">
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration
