import Logo from '../assets/img/logo.svg'
import CaretDown from '../assets/img/icons/CaretDown.svg'
import Tether from '../assets/img/tether.svg'
// import Search from '../assets/img/icons/search.svg'
import Globe from '../assets/img/icons/globe.svg'
import Logout from '../assets/img/icons/log-out.svg'
import ChevronDownOutline from '../assets/img/icons/chevron-down-outline.svg'
import Sharp from '../assets/img/icons/sharp.svg'
import Notification from '../assets/img/icons/Notification.svg'
import Avatar from '../assets/img/icons/clarity_avatar-solid.svg'
import ETH from '../assets/img/ethereum.svg'
import BTC from '../assets/img/bitcoin.svg'
import Matic from '../assets/img/matic.svg'
import Doge from '../assets/img/doge.svg'
import Dollar from '../assets/img/dollar.svg'
import Euro from '../assets/img/euro.svg'
import Ruble from '../assets/img/ruble.svg'
import Dirham from '../assets/img/dirham.svg'
import HomeIcon from '../assets/homePage/img/svg/home.svg'
import Wallet from '../assets/img/icons/aside-2.svg'
import SharedPurchase from '../assets/img/icons/shared-purchase.svg'
import P2P from '../assets/img/icons/p2p.svg'
import Institutional from '../assets/img/icons/institutional-investor.svg'
import Private from '../assets/img/icons/private-investor.svg'
import Key from '../assets/img/icons/key.svg'

import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../hooks/context'
import axios from 'axios'
import handleTokenRefresh from '../hooks/handleTokenRefresh'

import { Box, Popover } from '@mui/material'

function Header() {
  const { pathname } = useLocation()
  const { context, setContext } = useContext(UserContext)
  // const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [measureType, setMeasureType] = useState(context.currentUser?.preferences.measurementSystem || 'metric')
  const [currencyOpen, setCurrencyOpen] = useState(false)
  const [currencyChoice, setCurrencyChoice] = useState('USDT')
  const [logedIn, setLogedIn] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [searchInput, setSearchInput] = useState<string>('')

  const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    axios
      .get('https://api.statepix.io/api/v1/auth/logout', {
        headers: {
          Authorization: `Bearer ${context.accessToken}`,
          accept: 'application/json',
        },
      })
      .then(() => {
        setContext((prev: any) => {
          if (prev.accessToken) {
            return {}
          } else {
            return prev
          }
        })
        localStorage.removeItem('StatePix_accessToken')
        localStorage.removeItem('StatePix_refreshToken')
      })
      .catch(() => {
        setContext((prev: any) => {
          if (prev.accessToken) {
            return {}
          } else {
            return prev
          }
        })
        localStorage.removeItem('StatePix_accessToken')
        localStorage.removeItem('StatePix_refreshToken')
      })
    // setAnchorEl(null)
  }

  const changeMeasureType = () => {
    setContext((prev: any) => {
      return {
        ...prev,
        currentUser: {
          ...prev.currentUser,
          preferences: {
            ...prev.currentUser.preferences,
            measurementSystem: measureType === 'metric' ? 'imperial' : 'metric',
          },
        },
      }
    })
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation UpdateUserPreferences {
            updateUserPreferences(preferences: {
              language: "en",
              measurementSystem: ${measureType === 'metric' ? 'imperial' : 'metric'},
              theme: light
              }) {
                language
                measurementSystem
                theme
            }
          }
        `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          // result.data.data?.updateUserPreferences.measurementSystem
          //   ? setContext((prev: any) => ({
          //       ...prev,
          //       currentUser: {
          //         ...prev.currentUser,
          //         preferences: {
          //           ...prev.currentUser.preferences,
          //           measurementSystem: result.data.data?.updateUserPreferences.measurementSystem,
          //         },
          //       },
          //     }))
          //   : alert(result.data.data?.enableMFA.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setLogedIn(context.accessToken ? true : false)
    setMeasureType(context.currentUser?.preferences.measurementSystem || 'metric')
  }, [context])

  useEffect(() => {
    isMenuOpen ? document.body.classList.add('locked') : document.body.classList.remove('locked')
  }, [isMenuOpen])

  useEffect(() => {
    const animItems = document.querySelectorAll('.animate')

    function offset(el: HTMLElement) {
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    function animOnScroll() {
      animItems.forEach((animItem) => {
        const animItemElement = animItem as HTMLElement
        const animItemHeight = animItemElement.offsetHeight
        const animItemOffset = offset(animItemElement).top
        const animStart = 4

        let animItemPoint = window.innerHeight - animItemHeight / animStart
        if (animItemHeight > window.innerHeight) {
          animItemPoint = window.innerHeight - window.innerHeight / animStart
        }

        if (window.scrollY > animItemOffset - animItemPoint && window.scrollY < animItemOffset + animItemHeight) {
          animItem.classList.add('loaded')
        } else {
          if (!animItem.classList.contains('_anim-no-hide')) {
            animItem.classList.remove('loaded')
          }
        }
      })
    }

    // Initial load check
    animOnScroll()

    // Event listener setup
    window.addEventListener('scroll', animOnScroll)

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', animOnScroll)
    }
  }, [])

  useEffect(() => {
    setCurrencyOpen(false)
  }, [currencyChoice])

  const [menuList, setMenuList] = useState<
    {
      name: string
      big?: boolean
      subList?: {
        name: string
        link: string
        icon?: string
        description?: string
      }[]
      hover?: boolean
      link?: string
    }[]
  >([
    {
      name: 'Products',
      big: true,
      subList: [
        {
          name: 'Buying real estate',
          link: '/objects',
          icon: HomeIcon,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
        {
          name: 'Shared purchase of real estate',
          link: '/shared-objects',
          icon: SharedPurchase,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
        {
          name: 'P2P',
          link: '/p2p',
          icon: P2P,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
      ],
      hover: false,
    },
    {
      name: 'Technology',
      link: '/technology',
    },
    {
      name: 'Solutions',
      big: true,
      subList: [
        {
          name: 'For Institutional Investors',
          link: '/institutional-investors',
          icon: Institutional,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
        {
          name: 'For Private Investors',
          link: '/private-investors',
          icon: Private,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
        {
          name: 'For Personal Use',
          link: '/personal-use',
          icon: Wallet,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
        {
          name: 'For Realtors',
          link: '/realtors',
          icon: Key,
          description: 'Leo amet consectetur proin ornare turpis. Sit eget nec pellentesque volutpat.',
        },
      ],
      hover: false,
    },
    {
      name: 'Security',
      subList: [
        {
          name: 'Legal security',
          link: '/legal',
        },
        {
          name: 'Сryptographic security',
          link: '/cryptographic',
        },
      ],
      hover: false,
    },
    {
      name: 'Company',
      subList: [
        {
          name: 'About Us',
          link: '/about',
        },
        {
          name: 'Documents',
          link: '/documents',
        },
        {
          name: 'Contacts',
          link: '/contacts',
        },
        {
          name: 'Partners',
          link: '/partners',
        },
      ],
      hover: false,
    },
  ])

  const currencyList = {
    crypto: ['USDT', 'ETH', 'BTC', 'MATIC', 'DOGE'],
    fiat: ['USD', 'EUR', 'RUB', 'AED'],
  }

  const currencyIconHandler = (item: string) => {
    switch (item) {
      case 'USDT':
        return Tether
      case 'ETH':
        return ETH
      case 'BTC':
        return BTC
      case 'MATIC':
        return Matic
      case 'DOGE':
        return Doge
      case 'USD':
        return Dollar
      case 'EUR':
        return Euro
      case 'RUB':
        return Ruble
      case 'AED':
        return Dirham
      default:
        return Tether
    }
  }

  // const handleSearch = (event: any) => {
  //   if (event.key === 'Enter') {
  //     navigate('/objects', { state: { searchValue: searchInput } })
  //   }
  // }

  const mouseHover = (index: number) => {
    setMenuList((prev: any) => {
      return prev.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, hover: true }
        }
        return { ...item, hover: false }
      })
    })
  }
  const mouseUnhover = (index: number) => {
    setMenuList((prev: any) => {
      return prev.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, hover: false }
        }
        return { ...item, hover: false }
      })
    })
  }

  return (
    <header className="header">
      <nav className="nav" style={{ backgroundColor: pathname === '/home' ? '#fff' : '' }}>
        <div
          className={`navbar ${pathname === '/home' ? 'animate' : 'static'}`}
          style={{ maxWidth: pathname === '/home' ? '1400px' : '' }}
        >
          <div className="logo">
            <Link className="logo__link" to="/home">
              <img className="logo__img" width="100" height="21" src={Logo} alt="logo icon" />
            </Link>
          </div>

          <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
            <div
              className="menu__item menu__wrap--main"
              style={{
                justifyContent: pathname === '/home' ? 'center' : '',
                paddingLeft: pathname === '/home' ? '0' : '',
              }}
            >
              {menuList.map((item, index) => (
                <li
                  key={index}
                  onMouseEnter={() => {
                    mouseHover(index)
                  }}
                  onMouseLeave={() => {
                    mouseUnhover(index)
                  }}
                  className={`menu__item ${item.subList ? 'dropdown' : ''} menu__item--main ${
                    item.hover ? 'active' : ''
                  }`}
                >
                  <Link className="menu__item-link" to={item.link ?? pathname}>
                    {item.name}
                    {item.subList && <img src={CaretDown} alt="arrow icon" />}
                  </Link>

                  {item.subList && (
                    <ul className={`menu__sublist ${item.big ? 'menu__sublist--big' : ''}`}>
                      {item.subList.map((sub_item, index) => (
                        <li key={index} className="menu__subitem">
                          <Link className="menu__sublink" to={sub_item.link}>
                            {item.big ? (
                              <>
                                <div className="big-sublink button">
                                  <img src={sub_item.icon} className="big-sublink-icon" alt="home" />
                                </div>
                                <div className="big-sublink-text-wrap">
                                  <div className="big-sublink-title">{sub_item.name}</div>
                                  <div className="big-sublink-description">{sub_item.description}</div>
                                </div>
                              </>
                            ) : (
                              sub_item.name
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </div>
            {/* {logedIn && (
              <li className="menu__item menu__item-search">
                <div className="header-search" style={{ display: pathname === '/objects' ? 'none' : 'block' }}>
                  <input
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={handleSearch}
                    className="header-search__input"
                    type="search"
                    placeholder="Type here..."
                  />
                  <img className="header-search__icon" width="16" height="16" src={Search} alt="icon" />
                </div>
              </li>
            )} */}

            {logedIn && (
              <li className="menu__item menu__item-select">
                <div className={currencyOpen ? 'token-select active' : 'token-select'}>
                  <button
                    onClick={() => setCurrencyOpen(!currencyOpen)}
                    type="button"
                    className="token-select__selected"
                  >
                    <img
                      className="token-select__icon"
                      width="20"
                      height="20"
                      src={currencyIconHandler(currencyChoice)}
                      alt="token"
                    />
                    <p className="token-select__text">{currencyChoice}</p>
                    <img className="token-select__arrow" width="20" height="20" src={ChevronDownOutline} alt="token" />
                  </button>

                  <ul className="token-select__list">
                    {currencyList.crypto.map((item, index) => (
                      <li key={index} onClick={() => setCurrencyChoice(item)} className="token-select__item">
                        <img
                          className="token-select__icon"
                          width="20"
                          height="20"
                          src={currencyIconHandler(item)}
                          alt="token"
                        />
                        <p className="token-select__text">{item}</p>
                      </li>
                    ))}

                    <li className="token-select__item token-select__item--head">
                      <div className="token-select__item-border"></div>
                      <p className="token-select__item-label">FIAT</p>
                    </li>

                    {currencyList.fiat.map((item, index) => (
                      <li key={index} onClick={() => setCurrencyChoice(item)} className="token-select__item">
                        <img
                          className="token-select__icon"
                          width="20"
                          height="20"
                          src={currencyIconHandler(item)}
                          alt="token"
                        />
                        <p className="token-select__text">{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            )}

            {logedIn && (
              <li className="menu__item menu__item-ft-mt">
                <div
                  onClick={() => changeMeasureType()}
                  className={`menu__item-ft ${measureType === 'imperial' ? 'active' : ''}`}
                >
                  ft<sup>2</sup>
                </div>
                <div
                  onClick={() => changeMeasureType()}
                  className={`menu__item-mt ${measureType === 'metric' ? 'active' : ''}`}
                >
                  m<sup>2</sup>
                </div>
              </li>
            )}

            {logedIn && (
              <li className="menu__item menu__item-icons">
                <Link to="/" className="menu__item-link">
                  <img width="20" height="20" src={Globe} alt="globe icon" />
                </Link>

                {/* <button onClick={handlePopper} className="menu__item-link">
                  <img width="20" height="20" src={Sharp} alt="sharp icon" />
                </button> */}

                <Link to="/settings" className="menu__item-link">
                  <img width="20" height="20" src={Sharp} alt="sharp icon" />
                </Link>
                <>
                  <button onClick={handlePopper} className="menu__item-link">
                    <img width="20" height="20" src={Notification} alt="Notification icon" />
                  </button>

                  <Popover
                    slotProps={{
                      paper: {
                        sx: {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                    }}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                  >
                    <Box sx={{ padding: '15px', backgroundColor: 'transparent' }}>
                      <Box
                        sx={{ boxShadow: 3 }}
                        style={{
                          padding: '20px 15px',
                          width: '270px',
                          borderRadius: '15px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <div className="notification__header">
                          <div className="notification__title">Notifications</div>
                          <div className="notification__btn">Mark all as read</div>
                        </div>
                        <div className="notification__list">
                          <div className="notification__item unread">
                            Lorem ipsum dolor sit amet consetur. Risus sodales semper purus amet id nec risus.
                          </div>
                          <div className="notification__item">
                            Lorem ipsum dolor sit amet consetur. Risus sodales semper purus amet id nec risus.
                          </div>
                          <div className="notification__item">
                            Lorem ipsum dolor sit amet consetur. Risus sodales semper purus amet id nec risus.
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Popover>
                </>
                <Link to="/my-objects" className="menu__item-link">
                  <img width="20" height="20" src={Avatar} alt="avatar icon" />
                </Link>
                <button
                  onClick={handleLogout}
                  style={{
                    cursor: 'pointer',
                  }}
                  className="menu__item-link--logout"
                >
                  <img width="20" height="20" src={Logout} alt="avatar icon" />
                </button>
              </li>
            )}

            {!logedIn && (
              <li className="menu__item menu__item-btn">
                <div className="menu__actions animate">
                  <button className="menu__action menu__action--languages button button--transparent">
                    <img src={Globe} alt="language" className="menu__action-icon" />
                  </button>
                  <Link to="/login" className="menu__action menu__action--login button button--transparent animate">
                    Log In
                  </Link>
                  <Link to="/register" className="menu__action menu__action--register button animate">
                    REGISTER NOW
                  </Link>
                </div>
              </li>
            )}
          </ul>
          <div onClick={() => setIsMenuOpen(!isMenuOpen)} className={isMenuOpen ? 'burger active-burger' : 'burger'}>
            <span></span>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
