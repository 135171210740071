import '../styles/homePage/main.scss'

import Hero from '../assets/homePage/img/hero.svg'
import Technology from '../assets/homePage/img/technology.svg'
import Security01 from '../assets/homePage/img/security/01.svg'
import Security02 from '../assets/homePage/img/security/02.svg'
import Security03 from '../assets/homePage/img/security/03.svg'
import Security04 from '../assets/homePage/img/security/04.svg'
import Register from '../assets/homePage/img/svg/register.svg'
import HomeIcon from '../assets/homePage/img/svg/home.svg'
import Crypto from '../assets/homePage/img/svg/crypto.svg'
import Wallet from '../assets/homePage/img/svg/wallet.svg'
import Check from '../assets/homePage/img/svg/check.svg'
import Arrow from '../assets/homePage/img/svg/arrow.svg'
import ArrowExpanded from '../assets/homePage/img/svg/arrow-expanded.svg'
import Logo from '../assets/homePage/img/svg/logo.svg'
import ArrowBig from '../assets/homePage/img/svg/arrow-big.svg'

import { useEffect, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { Link } from 'react-router-dom'

function Home() {
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const [activeSlide, setActiveSlide] = useState<number>(1)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }
  useEffect(() => {
    const animItems = document.querySelectorAll('.animate')

    function offset(el: HTMLElement) {
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    function animOnScroll() {
      animItems.forEach((animItem) => {
        const animItemElement = animItem as HTMLElement
        const animItemHeight = animItemElement.offsetHeight
        const animItemOffset = offset(animItemElement).top
        const animStart = 4

        let animItemPoint = window.innerHeight - animItemHeight / animStart
        if (animItemHeight > window.innerHeight) {
          animItemPoint = window.innerHeight - window.innerHeight / animStart
        }

        if (window.scrollY > animItemOffset - animItemPoint && window.scrollY < animItemOffset + animItemHeight) {
          animItem.classList.add('loaded')
        } else {
          if (!animItem.classList.contains('_anim-no-hide')) {
            animItem.classList.remove('loaded')
          }
        }
      })
    }

    // Initial load check
    animOnScroll()

    // Event listener setup
    window.addEventListener('scroll', animOnScroll)

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', animOnScroll)
    }
  }, [])

  useEffect(() => {
    const items = document.querySelectorAll('.howitworks__item')
    if (activeIndex === items.length) {
      return
    }
    const interval = setInterval(() => {
      items.forEach((item, index) => {
        if (index === activeIndex) {
          item.classList.add('active')
        }
      })

      setActiveIndex((prevIndex) => prevIndex + 1)
    }, 800)

    return () => clearInterval(interval)
  }, [activeIndex])

  return (
    <>
      <section className="hero">
        <div className="hero__container">
          <div className="hero__main">
            <div className="hero__content">
              <h1 className="hero__title animate">
                <span className="hero__title-color">The best way</span> to buy and invest in real estate
              </h1>
              <p className="hero__text animate">
                StatePix is ​​a unique platform based on advanced tokenization and blockchain technologies, which makes
                it possible to buy and invest in real estate using cryptocurrency from anywhere in the world in a few
                clicks
              </p>
              <div className="hero__actions">
                <Link to="/objects" className="hero__action button animate">
                  VIEW OBJECTS
                </Link>
              </div>
            </div>
            <div className="hero__image">
              <img src={Hero} alt="hero" />
            </div>
          </div>
          <div className="hero__advantages advantages">
            <ul className="advantages__list">
              <li className="advantages__item">
                <div className="advantages__item-content">
                  Legal in 180 <span>countries</span>
                </div>
              </li>
              <li className="advantages__item">
                <div className="advantages__item-content">
                  100% verified <span>property</span>
                </div>
              </li>
              <li className="advantages__item">
                <div className="advantages__item-content">
                  Payment with leading <span>cryptocurrencies</span>
                </div>
              </li>
              <li className="advantages__item">
                <div className="advantages__item-content">
                  Based on reliable <span>smart contracts</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="howitworks section-offset">
        <div className="howitworks__container">
          <h2 className="howitworks__title title mb-title animate">How It Work</h2>

          <ul className="howitworks__items">
            <li className="howitworks__item">
              <div className="howitworks__image howitworks__image-1">
                <img src={Register} className="howitworks__image-icon howitworks__image-icon--1" alt="register" />
              </div>

              <h3 className="howitworks__item-title">Registration </h3>
              <p className="howitworks__item-text">
                Register on the site and get access to a catalog of real estate verified by experts
              </p>
            </li>
            <li className="howitworks__item">
              <div className="howitworks__image howitworks__image">
                <img src={HomeIcon} className="howitworks__image-icon" alt="home" />
              </div>
              <h3 className="howitworks__item-title">Select Object </h3>
              <p className="howitworks__item-text">
                Select the format for purchasing real estate: purchase, equity investment, P2P and option from the
                catalog{' '}
              </p>
            </li>
            <li className="howitworks__item">
              <div className="howitworks__image howitworks__image">
                <img src={Crypto} className="howitworks__image-icon" alt="crypto" />
              </div>
              <h3 className="howitworks__item-title">Select Crypto</h3>
              <p className="howitworks__item-text">
                Select the cryptocurrency for which it is convenient to purchase the selected object
              </p>
            </li>
            <li className="howitworks__item">
              <div className="howitworks__image howitworks__image">
                <img src={Wallet} className="howitworks__image-icon" alt="wallet" />
              </div>
              <h3 className="howitworks__item-title">Payment</h3>
              <p className="howitworks__item-text">Pay and receive an NFT that will confirm your ownership</p>
            </li>
            <li className="howitworks__item">
              <div className="howitworks__image howitworks__image">
                <img src={Check} alt="check" className="howitworks__image-icon" />
              </div>
              <h3 className="howitworks__item-title">Get fun!</h3>
              <p className="howitworks__item-text">
                Use NFTs as you wish: store, transfer, receive income and register real estate
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="technology section-offset">
        <div className="technology__container">
          <div className="technology__body">
            <div className="technology__content">
              <img src={Logo} className="technology__logo animate" alt="logo" />
              <h2 className="technology__title animate">
                StatePix Technology<span>©</span>
              </h2>
              <p className="technology__text animate">
                How we connect the Blockchain world with the real world using our unique technology
              </p>
              <div className="technology__actions">
                <Link to="/technology" className="technology__action button button--white ">
                  LEARN MORE
                </Link>
              </div>
            </div>
            <div className="technology__image">
              <img src={Technology} alt="technology" />
            </div>
          </div>
        </div>
      </section>

      <section className="products section-offset">
        <div className="products__container">
          <h2 className="products__title title mb-title animate">Our Products</h2>
          <ul className="products__list">
            <li
              onMouseEnter={() => setActiveSlide(1)}
              className={`products__item item-products item-products--1 ${activeSlide === 1 ? 'active' : ''}`}
            >
              <div className="item-products__body">
                <h3 className="item-products__title">Buying Real Estate</h3>
                <div className="item-products__text">Buy verified properties simply and securely as never before</div>
              </div>

              <Link to="/objects" className="item-products__link">
                <img src={ArrowBig} alt="arrow" className="item-products__link-icon" />
              </Link>
            </li>
            <li
              onMouseEnter={() => setActiveSlide(2)}
              className={`products__item item-products item-products--2 ${activeSlide === 2 ? 'active' : ''}`}
            >
              <div className="item-products__body">
                <h3 className="item-products__title">Equity investment</h3>
                <div className="item-products__text">
                  Invest in high-yield real estate from 1000 USDT, buying it in parts
                </div>
              </div>

              <Link to="/shared-objects" className="item-products__link">
                <img src={ArrowBig} alt="arrow" className="item-products__link-icon" />
              </Link>
            </li>
            <li
              onMouseEnter={() => setActiveSlide(3)}
              className={`products__item item-products item-products--3 ${activeSlide === 3 ? 'active' : ''}`}
            >
              <div className="item-products__body">
                <h3 className="item-products__title">P2P</h3>
                <div className="item-products__text">
                  Invest in high-yield real estate from 1000 USDT, buying it in parts
                </div>
              </div>
              <Link to="/p2p" className="item-products__link">
                <img src={ArrowBig} alt="arrow" className="item-products__link-icon" />
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section className="security section-offset">
        <div className="security__container">
          <h2 className="security__title title mb-title  animate">Security</h2>

          <ul className="security__items">
            <li className="security__item security__item--1 animate">
              <div className="security__image security__image-1">
                <img src={Security01} alt="icon" />
              </div>

              <h3 className="security__item-title">Only existing real estate </h3>
              <p className="security__item-text">
                Thanks to the use of blockchain and full verification of objects by experts, only existing real estate
                is presented on the site
              </p>
            </li>
            <li className="security__item security__item--2 animate">
              <div className="security__image security__image">
                <img src={Security02} alt="icon" />
              </div>
              <h3 className="security__item-title">Safe storage assets</h3>
              <p className="security__item-text">
                We use the best encryption systems, which guarantees the security of your funds at all times.
              </p>
            </li>
            <li className="security__item security__item--3 animate">
              <div className="security__image security__image">
                <img src={Security03} alt="icon" />
              </div>
              <h3 className="security__item-title">Reliable protection account</h3>
              <p className="security__item-text">
                We adhere to the highest security standards and use the strictest security methods to ensure the safety
                of your account
              </p>
            </li>
            <li className="security__item security__item--4 animate">
              <div className="security__image security__image">
                <img src={Security04} alt="icon" />
              </div>
              <h3 className="security__item-title">Freezing course</h3>
              <p className="security__item-text">
                 To minimize the risk of losing funds on the cryptocurrency exchange rate, we use an advanced rate
                freezing system at the time of the transactios
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="faq section-offset">
        <div className="faq__container">
          <h2 className="faq__title title animate">Frequently asked questions</h2>
          <div data-spollers data-one-spoller className="faq__spollers spollers-faq">
            <ul className="spollers-faq__list">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                className="spollers-faq__item"
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      alt="arrow"
                      className={expanded === 'panel1' ? 'spollers-arrow expanded-arrow' : 'spollers-arrow'}
                      src={expanded === 'panel1' ? ArrowExpanded : Arrow}
                    />
                  }
                  className="spollers-faq__title"
                  id="panel1-header"
                >
                  What kind of real estate can I buy?
                </AccordionSummary>
                <AccordionDetails className="spollers-faq__body">
                  Our platform provides access to various types of real estate, including apartments, houses, commercial
                  properties and land.
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                className="spollers-faq__item"
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      alt="arrow"
                      className={expanded === 'panel2' ? 'spollers-arrow expanded-arrow' : 'spollers-arrow'}
                      src={expanded === 'panel2' ? ArrowExpanded : Arrow}
                    />
                  }
                  className="spollers-faq__title"
                  id="panel2-header"
                >
                  How does the transfer of real estate take place after purchase?
                </AccordionSummary>
                <AccordionDetails className="spollers-faq__body">
                  Our platform provides access to various types of real estate, including apartments, houses, commercial
                  properties and land.
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                className="spollers-faq__item"
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      alt="arrow"
                      className={expanded === 'panel3' ? 'spollers-arrow expanded-arrow' : 'spollers-arrow'}
                      src={expanded === 'panel3' ? ArrowExpanded : Arrow}
                    />
                  }
                  className="spollers-faq__title"
                  id="panel3-header"
                >
                  Can I invest in real estate with small amounts?
                </AccordionSummary>
                <AccordionDetails className="spollers-faq__body">
                  Our platform provides access to various types of real estate, including apartments, houses, commercial
                  properties and land.
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
                className="spollers-faq__item"
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      alt="arrow"
                      className={expanded === 'panel4' ? 'spollers-arrow expanded-arrow' : 'spollers-arrow'}
                      src={expanded === 'panel4' ? ArrowExpanded : Arrow}
                    />
                  }
                  className="spollers-faq__title"
                  id="panel4-header"
                >
                  What are the possibilities of interaction with the purchased property?
                </AccordionSummary>
                <AccordionDetails className="spollers-faq__body">
                  Our platform provides access to various types of real estate, including apartments, houses, commercial
                  properties and land.
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
                className="spollers-faq__item"
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      alt="arrow"
                      className={expanded === 'panel5' ? 'spollers-arrow expanded-arrow' : 'spollers-arrow'}
                      src={expanded === 'panel5' ? ArrowExpanded : Arrow}
                    />
                  }
                  className="spollers-faq__title"
                  id="panel5-header"
                >
                  How can I be sure that my investment is safe?
                </AccordionSummary>
                <AccordionDetails className="spollers-faq__body">
                  Our platform provides access to various types of real estate, including apartments, houses, commercial
                  properties and land.
                </AccordionDetails>
              </Accordion>
            </ul>
          </div>
        </div>
      </section>

      <section className="register section-offset">
        <div className="register__container">
          <div className="register__body">
            <h2 className="register__title animate">Buy and invest in real estate with StatePix</h2>
            <Link to="/register" className="register__btn button button--white">
              REGISTER NOW
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
