import Gallery1 from '../assets/img/gallery/gallery-1.jpg'
import Advantage1 from '../assets/img/icons/card-advantage-1.svg'
import Advantage2 from '../assets/img/icons/card-advantage-2.svg'
import Advantage3 from '../assets/img/icons/card-advantage-3.svg'
import Tether from '../assets/img/tether.svg'
import ETH from '../assets/img/ethereum.svg'
import BTC from '../assets/img/bitcoin.svg'
import Matic from '../assets/img/matic.svg'
import Doge from '../assets/img/doge.svg'
import ChevronDownOutline from '../assets/img/icons/chevron-down-outline.svg'
import Marker from '../assets/img/icons/marker.svg'
import LogoBlack from '../assets/img/card-logo-black.svg'
import Completed from '../assets/img/verification-completed-check.svg'
import CloseModal from '../assets/img/icons/close-modal.svg'

import { Link, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { CircularProgress, FormControlLabel, Radio, RadioGroup, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import { UserContext } from '../hooks/context'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import { AdvancedMarker, APIProvider, ControlPosition, Map } from '@vis.gl/react-google-maps'
import RelatedObjects from '../components/RelatedObjects'
import measureSquareConvert from '../hooks/measureSquareConvert'
import measureConvert from '../hooks/measureConvert'

function Object() {
  const { context, setContext } = useContext(UserContext)

  const width = window.innerWidth
  const { objectId } = useParams()

  const [object, setObject] = useState<any>(null)
  const [errorText, setErrorText] = useState<string>('')
  const [buyPartInput, setBuyPartInput] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [modalShow, setModalShow] = useState<string>('')
  const [activeTab, setActiveTab] = useState<number>(1)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [currencyChoice, setCurrencyChoice] = useState<string>('USDT')
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [sellModal, setSellModal] = useState<boolean>(false)
  const [sellingPrice, setSellingPrice] = useState<string>('')
  const [sellingByParts, setSellingByParts] = useState<string>('full')
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>('')

  const currencyList = {
    crypto: ['USDT', 'ETH', 'BTC', 'MATIC', 'DOGE'],
  }

  const currencyIconHandler = (item: string) => {
    switch (item) {
      case 'USDT':
        return Tether
      case 'ETH':
        return ETH
      case 'BTC':
        return BTC
      case 'MATIC':
        return Matic
      case 'DOGE':
        return Doge
      default:
        return Tether
    }
  }

  useEffect(() => {
    modalShow ? document.body.classList.add('locked') : document.body.classList.remove('locked')
  }, [modalShow])

  useEffect(() => {
    setCurrencyOpen(false)
  }, [currencyChoice])

  useEffect(() => {
    object
      ? // ? setBuyPartInput(((object.price / object.area) * object.stockInfo?.minAllowedArea).toFixed(2))
        setBuyPartInput(Math.floor((object.partPrice || object.price) * 100) / 100)
      : setBuyPartInput('')
  }, [object])

  useEffect(() => {
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query object {
            object(id: ${objectId}) {
              stockInfo {
                isPartialSale
                minAllowedArea
              }
              id
              name
              description
              files
              facilities
              readinessStatus
              type
              isPart
              partPrice
              partPercentage
              partArea
              price
              floor
              area
              ceilingHeight
              layout
              userId
              address {
                country
                city
                street
                house
                flat
              }
              coordinates {
                lat
                lon
              }
              yearOfConstruction
              numberOfRooms
              numberOfBedrooms
              numberOfBathrooms
              parking
              elevator
              powerSupply
              security
              repair
              view
              relatedObjects {
                id
                name
                type
                area
                numberOfBathrooms
                numberOfBedrooms
                address {
                  city
                  street
                  house
                  flat
                }
              }
            }
          }
        `,
      },
    })
      .then((result) => {
        result.data.data?.object
          ? setObject(result.data.data.object)
          : setErrorText('There is no object with this ID: ' + objectId)
      })
      .catch((error) => {
        if (error.response?.errorStatus === 401) {
          handleTokenRefresh(setContext)
        }
      })
  }, [objectId, setContext, context, modalShow])

  const buyObjectHandler = () => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation BuyObject {
            buyObject(id: ${object.id}${
          buyPartInput &&
          object.stockInfo.isPartialSale &&
          Math.floor(Number(buyPartInput) * 100) !== Math.floor((object.partPrice ?? object.price) * 100)
            ? ', amount: ' + buyPartInput
            : ''
        }) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else if (result.data.data?.buyObject.success) {
          setModalShow('buy')
        } else if (result.data.data?.buyObject.message === 'OBJECT_NOT_IN_STOCK') {
          setAlertText("This object isn't in stock. Probably it was bought by another user.")
          setAlertOpen(true)
        } else if (result.data.data?.buyObject.message === 'INSUFFICIENT_FUNDS') {
          setAlertText("You don't have enough funds to buy this object.")
          setAlertOpen(true)
        } else {
          setAlertText(result.data.data?.buyObject.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  const sellObjectHandler = () => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation SellObject {
            sellObject(input: {
              id: ${object.id},
              price: ${sellingPrice},
              isPartialSale: ${sellingByParts === 'part' ? true : false},
            }) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else if (result.data.data?.sellObject.success) {
          setSellModal(false)
          setModalShow('sell')
        } else {
          setAlertText(result.data.data?.sellObject.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return object ? (
    <>
      <section className="object">
        <div className="object__inner">
          <div className="object__top">
            <div className="object__gallery">
              <Swiper
                style={
                  {
                    '--swiper-navigation-color': '#fff',
                    '--swiper-navigation-size': '20px',
                    '--swiper-pagination-color': '#fff',
                  } as any
                }
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {object.files.length ? (
                  object.files.map((image: string) => (
                    <SwiperSlide key={image}>
                      <img src={`https://statepix.freeblock.site/uploads/objects/${image}`} alt="galley pic" />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img src={Gallery1} alt="galley pic" />
                  </SwiperSlide>
                )}
              </Swiper>
              <Swiper
                onSwiper={(swiper: SwiperClass) => setThumbsSwiper(swiper)}
                direction={width < 620 || (width > 1450 && width < 1580) ? 'horizontal' : 'vertical'}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {object.files.length ? (
                  object.files.map((image: string) => (
                    <SwiperSlide key={image}>
                      <img src={`https://statepix.freeblock.site/uploads/objects/${image}`} alt="galley pic" />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img src={Gallery1} alt="galley pic" />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>

            <div className="object__info">
              <div className="object__info-head">
                <h2 className="object__info-title">
                  {object.name}, {measureSquareConvert(context, object.area)}
                  <sup>2</sup>
                </h2>
                <p className="object__info-location">
                  {object.address.city}, {object.address.house} {object.address.street}, {object.floor} floor
                </p>
                {/* <div className="object__info-points">
                  <p className="object__info-point">{object.features[0]}, </p>
                  <p className="object__info-point">{object.features[1]}, </p>
                  <p className="object__info-point">{object.features[2]}</p>
                </div> */}
              </div>

              <div className="object__facilities">
                <h3 className="object__facilities-label">Facilities</h3>
                <p className="object__facilities-descr">{object.facilities}</p>

                <div className="object__facilities-advantages card__advantages">
                  <div className="card__advantage">
                    <img className="card__advantage-icon" width="24" height="24" src={Advantage1} alt="icon" />

                    <div className="card__advantage-texts">
                      <p className="card__advantage-text">
                        {measureSquareConvert(context, object.area)}
                        <sup>2</sup>
                      </p>
                    </div>
                  </div>

                  <div className="card__advantage">
                    <img className="card__advantage-icon" width="24" height="24" src={Advantage2} alt="icon" />

                    <div className="card__advantage-texts">
                      <p className="card__advantage-text">{object.numberOfBedrooms}</p>
                    </div>
                  </div>

                  <div className="card__advantage">
                    <img className="card__advantage-icon" width="24" height="24" src={Advantage3} alt="icon" />

                    <div className="card__advantage-texts">
                      <p className="card__advantage-text">{object.numberOfBathrooms}</p>
                    </div>
                  </div>
                </div>

                <ul className="object__facilities-list">
                  <li className="object__facilities-item">
                    Openspace: {measureSquareConvert(context, object.area)}
                    <sup>2</sup>
                  </li>
                  <li className="object__facilities-item">Floor number: {object.floor}</li>
                  <li className="object__facilities-item">Repair: {object.repair}</li>
                  <li className="object__facilities-item">Power supply: {object.powerSupply ? 'Yes' : 'No'}</li>
                  <li className="object__facilities-item">
                    Ceiling height: {measureConvert(context, object.ceilingHeight, 1)}
                  </li>
                  <li className="object__facilities-item">Parking: {object.parking ? 'Yes' : 'No'}</li>
                  <li className="object__facilities-item">Elevators: {object.elevator ? 'Yes' : 'No'}</li>
                </ul>
              </div>

              {context.currentUser?.id === object.userId ? (
                <div className="object__prising">
                  <div className="object__prising-columns">
                    <div className="object__prising-column">
                      <p className="object__prising-title">Price</p>
                      <p className="object__prising-label">Purchase Price</p>
                      <div className="object__prising-sum">~${(object.partPrice ?? object.price).toLocaleString()}</div>
                    </div>
                  </div>

                  <div className="object__btns">
                    <button onClick={() => setSellModal(true)} type="button" className="object__btn button btn">
                      SELL
                    </button>
                    <button type="button" className="object__btn btn button--transparent">
                      TRANSFER
                    </button>
                  </div>
                </div>
              ) : object.stockInfo?.isPartialSale ? (
                <div className="object__prising">
                  <div className="object__blocks">
                    <div className="object__percent">
                      <div
                        className="object__percent-progressbar"
                        role="progressbar"
                        aria-valuenow={object.partPercentage?.toFixed(0) || 100}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ '--value': object.partPercentage?.toFixed(0) || 100 } as React.CSSProperties}
                      ></div>

                      <div className="object__percent-rows">
                        <div className="object__percent-row">
                          <div className="object__percent-name">
                            <div className="object__percent-name-fiol"></div>
                            <div className="object__percent-name-text">Can buy</div>
                          </div>
                          <div className="object__percent-sums">
                            {measureSquareConvert(context, object.partArea || object.area)}
                            <sup>2</sup> ({object.partPercentage?.toFixed(0) || 100}%)
                          </div>
                        </div>

                        <div className="object__percent-row">
                          <div className="object__percent-name">
                            <div className="object__percent-name-grey"></div>
                            <div className="object__percent-name-text">Sold</div>
                          </div>
                          <div className="object__percent-sums">
                            {measureSquareConvert(context, object.partArea ? object.area - object.partArea : 0)}
                            <sup>2</sup> ({100 - object.partPercentage?.toFixed(0) || 0}%)
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="object__btns object__btns--four">
                      <div className={currencyOpen ? 'object__btn-token active' : 'object__btn-token'}>
                        <p className="object__prising-label">CHOOSE TOKEN</p>

                        <button
                          onClick={() => setCurrencyOpen(!currencyOpen)}
                          type="button"
                          className="object__btn-select"
                        >
                          <div className="object__btn-selected">
                            <img
                              className="object__btn-selected-icon"
                              width="20"
                              height="20"
                              src={currencyIconHandler(currencyChoice)}
                              alt="token"
                            />
                            <span className="object__btn-selected-text">{currencyChoice}</span>
                            <img
                              className="object__btn-selected-arrow"
                              width="12"
                              height="12"
                              src={ChevronDownOutline}
                              alt="icon"
                            />
                          </div>
                        </button>
                        <ul className="token-select__list">
                          {currencyList.crypto.map((item, index) => (
                            <li key={index} onClick={() => setCurrencyChoice(item)} className="token-select__item">
                              <img
                                className="token-select__icon"
                                width="20"
                                height="20"
                                src={(() => {
                                  switch (item) {
                                    case 'USDT':
                                      return Tether
                                    case 'ETH':
                                      return ETH
                                    case 'BTC':
                                      return BTC
                                    case 'MATIC':
                                      return Matic
                                    case 'DOGE':
                                      return Doge
                                    default:
                                      return Tether
                                  }
                                })()}
                                alt="token"
                              />
                              <p className="token-select__text">{item}</p>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="object__btn-token">
                        <p className="object__prising-label">ENTER AMOUNT</p>

                        <div className="object__amount">
                          <input
                            className="object__amount-input"
                            type="number"
                            value={buyPartInput}
                            onChange={(e) => setBuyPartInput(e.target.value.replace(/^0+/, ''))}
                          />
                          <div className="object__amount-token">USDT</div>
                        </div>
                      </div>

                      <button type="button" className="object__btn object__btn--grey btn">
                        {measureSquareConvert(context, (Number(buyPartInput) * object.area) / object.price)}
                        <sup>2</sup> ({((Number(buyPartInput) * 100) / object.price).toFixed(0)} %)
                      </button>
                      {context.accessToken ? (
                        !loading ? (
                          <button
                            type="button"
                            onClick={() => buyObjectHandler()}
                            disabled={
                              !buyPartInput ||
                              (Number(buyPartInput) * (object.partArea ?? object.area)) /
                                (object.partPrice ?? object.price) <
                                object.stockInfo.minAllowedArea ||
                              Number(buyPartInput) > (object.partPrice ?? object.price)
                            }
                            className={`object__btn button btn ${
                              !buyPartInput ||
                              (Number(buyPartInput) * (object.partArea ?? object.area)) /
                                (object.partPrice ?? object.price) <
                                object.stockInfo.minAllowedArea ||
                              Number(buyPartInput) > (object.partPrice ?? object.price)
                                ? 'disabled'
                                : ''
                            }`}
                          >
                            BUY NOW
                          </button>
                        ) : (
                          <CircularProgress sx={{ color: '#e488cc', justifySelf: 'center' }} />
                        )
                      ) : (
                        <Link to={'/login'} className={`object__btn button btn`}>
                          LOGIN
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="object__prising">
                  <div className="object__prising-columns">
                    <div className="object__prising-column">
                      <p className="object__prising-title">Price</p>
                      <p className="object__prising-label">Purchase Price</p>
                      <div className="object__prising-sum">$ {(object.partPrice ?? object.price).toLocaleString()}</div>
                    </div>
                  </div>

                  <div className="object__btns object__btns--end">
                    <div className={currencyOpen ? 'object__btn-token active' : 'object__btn-token'}>
                      <p className="object__prising-label">Choose token</p>

                      <button
                        onClick={() => setCurrencyOpen(!currencyOpen)}
                        type="button"
                        className="object__btn-select"
                      >
                        <div className="object__btn-selected">
                          <img
                            className="object__btn-selected-icon"
                            width="20"
                            height="20"
                            src={currencyIconHandler(currencyChoice)}
                            alt="token"
                          />
                          <span className="object__btn-selected-text">{currencyChoice}</span>
                          <img
                            className="object__btn-selected-arrow"
                            width="12"
                            height="12"
                            src={ChevronDownOutline}
                            alt="icon"
                          />
                        </div>
                      </button>
                      <ul className="token-select__list">
                        {currencyList.crypto.map((item, index) => (
                          <li key={index} onClick={() => setCurrencyChoice(item)} className="token-select__item">
                            <img
                              className="token-select__icon"
                              width="20"
                              height="20"
                              src={(() => {
                                switch (item) {
                                  case 'USDT':
                                    return Tether
                                  case 'ETH':
                                    return ETH
                                  case 'BTC':
                                    return BTC
                                  case 'MATIC':
                                    return Matic
                                  case 'DOGE':
                                    return Doge
                                  default:
                                    return Tether
                                }
                              })()}
                              alt="token"
                            />
                            <p className="token-select__text">{item}</p>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* <div className="object__btn object__btn-change btn-outline">
                <button type="button" className="object__btn-plus">-</button>
                <span>5</span>
                <button type="button" className="object__btn-minus">+</button>
              </div> */}

                    {context.accessToken ? (
                      !loading ? (
                        <button type="button" onClick={() => buyObjectHandler()} className={`object__btn button btn`}>
                          BUY NOW
                        </button>
                      ) : (
                        <CircularProgress sx={{ color: '#e488cc', justifySelf: 'center' }} />
                      )
                    ) : (
                      <Link to={'/login'} className={`object__btn button btn`}>
                        LOGIN
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="object__content">
            <div className="object__tabs">
              <div className="object__tabs-head">
                <button
                  type="button"
                  onClick={() => setActiveTab(1)}
                  className={`object__tabs-btn ${activeTab === 1 ? 'active' : ''}`}
                  data-tab-btn="Description"
                >
                  Description
                </button>
                <button
                  type="button"
                  onClick={() => setActiveTab(2)}
                  className={`object__tabs-btn ${activeTab === 2 ? 'active' : ''}`}
                  data-tab-btn="Tech specification"
                >
                  Tech specification
                </button>
              </div>

              <div className="object__tabs-contents">
                <div
                  className={`object__tabs-content ${activeTab === 1 ? 'active' : ''}`}
                  data-tab-content="Description"
                >
                  <div className="object__tabs-texts">
                    <p className="object__tabs-text">{object.description}</p>
                  </div>
                </div>

                <div
                  className={`object__tabs-content ${activeTab === 2 ? 'active' : ''}`}
                  data-tab-content="Tech specification"
                >
                  <ul className="object__tabs-list">
                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Floor number: </p>
                      <p className="object__tabs-text">{object.floor}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black"> Ceiling height: </p>
                      <p className="object__tabs-text">{measureConvert(context, object.ceilingHeight, 1)}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Power supply: </p>
                      <p className="object__tabs-text">{object.powerSupply ? 'Yes' : 'No'}</p>
                    </li>

                    {/* <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">
                        Air conditioning and climate control:{' '}
                      </p>
                      <p className="object__tabs-text">{object.airConditioning ? 'Yes' : 'No'}</p>
                    </li> */}

                    {/* <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Water supply and sewerage: </p>
                      <p className="object__tabs-text">{object.waterSupply ? 'Yes' : 'No'}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Gas supply: </p>
                      <p className="object__tabs-text">{object.gasSupply ? 'Yes' : 'No'}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Internet and telecommunications: </p>
                      <p className="object__tabs-text">{object.internet ? 'Yes' : 'No'}</p>
                    </li> */}

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Repair: </p>
                      <p className="object__tabs-text">{object.repair.replace(/_/g, ' ')}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">View: </p>
                      <p className="object__tabs-text">{object.view}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Year of construction: </p>
                      <p className="object__tabs-text">{object.yearOfConstruction}</p>
                    </li>

                    {/* <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Structural elements: </p>
                      <p className="object__tabs-text">{object.structuralElements}</p>
                    </li> */}

                    {/* <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black"> Heating and cooling systems: </p>
                      <p className="object__tabs-text">{object.heatingCooling ? 'Yes' : 'No'}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Fire safety: </p>
                      <p className="object__tabs-text">{object.fireSafety ? 'Yes' : 'No'}</p>
                    </li> */}

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black">Elevators: </p>
                      <p className="object__tabs-text">{object.elevator ? 'Yes' : 'No'}</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black"> Parking: </p>
                      <p className="object__tabs-text">{object.parking} places</p>
                    </li>

                    <li className="object__tabs-item">
                      <p className="object__tabs-text object__tabs-text--black"> Security: </p>
                      <p className="object__tabs-text">{object.security ? 'Yes' : 'No'}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="object__map">
              <APIProvider apiKey="AIzaSyC0Eycy7xeMtl_LGFExkrW6aiJb3FFeEQA">
                <Map
                  mapId="1a01564b1f987586"
                  style={{ width: '100%', height: '100%', borderRadius: '15px' }}
                  defaultCenter={{ lat: object.coordinates.lat, lng: object.coordinates.lon }}
                  defaultZoom={13}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                  fullscreenControl={true}
                  fullscreenControlOptions={{
                    position: ControlPosition.RIGHT_BOTTOM,
                  }}
                >
                  <AdvancedMarker position={{ lat: object.coordinates.lat, lng: object.coordinates.lon }}>
                    <img src={Marker} alt="marker" />
                  </AdvancedMarker>
                  {/* <MapControl position={ControlPosition.RIGHT_BOTTOM}>
                    <button type="button" className="buy-object__fullscreen">
                      <img width="20" height="20" src={Fullscreen} alt="icon" />
                    </button>
                  </MapControl> */}
                </Map>
              </APIProvider>
            </div>
          </div>
        </div>
      </section>
      <div className={`modal ${loading && 'initiate'} ${modalShow && 'show'}`} id="withdraw2">
        <div className="modal-content">
          <div className="modal__form modal__form--mini">
            <div className="modal__head">
              <div className="modal__logo">
                <img width="100" height="21" src={LogoBlack} alt="logo" />
              </div>

              <img className="modal__check-pic" width="160" height="160" src={Completed} alt="check" />

              {modalShow === 'buy' ? (
                <h2 className="modal__title">Transaction completed successfully</h2>
              ) : (
                <h2 className="modal__title">Object is on sale now</h2>
              )}

              <p className="modal__text">
                Lorem ipsum dolor sit amet consectetur. Amet ullamcorper mauris sollicitudin odio odio ornare proin.
                Rutrum sem pretium augue tempus odio.
              </p>

              <button onClick={() => setModalShow('')} className="close-modal-btn">
                <img src={CloseModal} alt="close" />
              </button>
            </div>

            <div className="modal__footer">
              <Link
                to={modalShow === 'buy' ? '/my-objects' : '/orders'}
                onClick={() => setModalShow('')}
                className="modal__btn button btn"
              >
                {modalShow === 'buy' ? 'MY OBJECTS' : 'MY ORDERS'}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${loading && 'initiate'} ${sellModal && 'show'}`} id="sell2">
        <div className="modal-content">
          <div className="modal__form modal__form--mini">
            <div className="modal__head modal__head--left">
              <h2 className="modal__title">{object.name}</h2>

              <TextField
                value={sellingPrice}
                onChange={(e) => setSellingPrice(e.target.value)}
                variant="outlined"
                type="number"
                size="small"
                placeholder="Enter amount"
                style={{ width: '100%' }}
                label="Price"
              />

              <RadioGroup
                onChange={(e) => setSellingByParts(e.target.value)}
                value={sellingByParts}
                defaultValue={false}
                name="radio-buttons-group"
              >
                <FormControlLabel value="part" control={<Radio />} label="Partial sale" />
                <FormControlLabel value="full" control={<Radio />} label="Full object sale" />
              </RadioGroup>

              <button onClick={() => setSellModal(false)} className="close-modal-btn">
                <img src={CloseModal} alt="close" />
              </button>
            </div>

            <div className="modal__footer">
              <button onClick={() => sellObjectHandler()} type="submit" className="modal__btn button btn">
                Sell
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertText}
        autoHideDuration={3000}
        ContentProps={{
          sx: {
            backgroundColor: '#e488cc',
            color: '#fff',
          },
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            borderRadius: '10px',
            marginTop: '75px',
          },
        }}
      />
      <RelatedObjects list={object.relatedObjects} />
    </>
  ) : (
    <>
      {!errorText && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', color: '#e488cc' }} />}
      <div>{errorText}</div>
    </>
  )
}

export default Object
