import Calendar from '../assets/img/icons/datepicker.svg'
import Card from '../assets/img/card.jpg'
import Advantage1 from '../assets/img/icons/card-advantage-1.svg'
import Advantage2 from '../assets/img/icons/card-advantage-2.svg'
import Advantage3 from '../assets/img/icons/card-advantage-3.svg'
import Create from '../assets/img/icons/create.svg'
import Trash from '../assets/img/icons/trash.svg'
import LogoBlack from '../assets/img/card-logo-black.svg'
import CloseModal from '../assets/img/icons/close-modal.svg'
import Completed from '../assets/img/verification-completed-check.svg'

import { useContext, useEffect, useState } from 'react'
import {
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { UserContext } from '../hooks/context'
import DatePicker from 'react-datepicker'
import measureSquareConvert from '../hooks/measureSquareConvert'
import axios from 'axios'
import moment from 'moment'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import { useNavigate } from 'react-router-dom'

function Orders() {
  const { context, setContext } = useContext(UserContext)
  const navigate = useNavigate()
  const [orders, setOrders] = useState<any[]>([])
  const [isPartialSale, setIsPartialSale] = useState<string>('')
  // const [objectPurchase, setObjectPurchase] = useState<string>('')
  // const [objectLimit, setObjectLimit] = useState<string>('')
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [startDate, endDate] = dateRange
  const [loadingTable, setLoadingTable] = useState<boolean>(true)
  const [datepickerRangeError, setDatepickerRangeError] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<number>(0)
  const [deleteModal, setDeleteModal] = useState<number>(0)
  const [sellingPrice, setSellingPrice] = useState<string>('')
  const [sellingByParts, setSellingByParts] = useState<string>('full')
  const [loading, setLoading] = useState<boolean>(false)
  const [editSuccess, setEditSuccess] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>('')

  useEffect(() => {
    if (editSuccess === false && deleteSuccess === false) {
      setLoadingTable(true)
      axios({
        url: 'https://api.statepix.io/graphql',
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer ' + context.accessToken,
        },
        method: 'post',
        data: {
          query: `
            query StockObjects {
              stockObjects(filter: {${isPartialSale && `isPartialSale: ${isPartialSale}`}, ${
            startDate && endDate
              ? `period: { from: "${moment(startDate).format('YYYY-MM-DD')}", to: "${moment(endDate).format(
                  'YYYY-MM-DD'
                )}" }`
              : ''
          } }) {
                stockInfo {
                  isPartialSale
                  createdAt
                }
                id
                isPart
                name
                files
                price
                partPrice
                partPercentage
                area
                partArea
                address {
                  city
                  street
                  house
                  flat
                }
                numberOfBedrooms
                numberOfBathrooms
              }
            }
            `,
        },
      })
        .then((result) => {
          if (result.data.data === null) {
            if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
              handleTokenRefresh(setContext)
            } else {
              alert(result.data.errors?.[0].message)
            }
          } else {
            setOrders(result.data.data.stockObjects)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => setLoadingTable(false))
    }
  }, [context.accessToken, setContext, setOrders, isPartialSale, startDate, endDate, editSuccess, deleteSuccess])

  useEffect(() => {
    setSellingPrice(
      orders.find((o) => o.id === editModal)?.partPrice ?? orders.find((o) => o.id === editModal)?.price ?? ''
    )
    setSellingByParts(orders.find((o) => o.id === editModal)?.stockInfo.isPartialSale ? 'part' : 'full')
  }, [editModal, orders])

  const handleOrderEdit = (id: number) => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation UpdateStockInfo {
            updateStockInfo(input: { id: ${id}, price: ${sellingPrice}, isPartialSale: ${
          sellingByParts === 'part' ? true : false
        } }) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else if (result.data.data?.updateStockInfo.success) {
          setEditSuccess(true)
          setEditModal(0)
        } else {
          setAlertText(result.data.data?.updateStockInfo.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }
  const handleOrderDelete = (id: number) => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation WithdrawObjectFromStock {
            withdrawObjectFromStock(id: ${id}) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else if (result.data.data?.withdrawObjectFromStock.success) {
          setDeleteSuccess(true)
          setDeleteModal(0)
        } else {
          setAlertText(result.data.data?.withdrawObjectFromStock.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return context.currentUser ? (
    <section className="orders">
      <div className="orders__inner">
        <div className="orders__head">
          <h2 className="orders__title">My Orders</h2>
        </div>

        <div className="orders__content">
          <div className="orders__filters">
            {/* <Select
              size="small"
              value={objectLimit}
              onChange={(e) => {
                setObjectLimit(e.target.value)
              }}
              style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>

              <MenuItem value="limit">Limit</MenuItem>
              <MenuItem value="limit2">Limit2</MenuItem>
            </Select> */}
            {/* <Select
              size="small"
              value={objectPurchase}
              onChange={(e) => {
                setObjectPurchase(e.target.value)
              }}
              style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>

              <MenuItem value="purchase">Purchase</MenuItem>
              <MenuItem value="purchase2">Purchase2</MenuItem>
            </Select> */}
            <Select
              size="small"
              value={isPartialSale}
              onChange={(e) => {
                setIsPartialSale(e.target.value)
              }}
              style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>

              <MenuItem value="false">Full object</MenuItem>
              <MenuItem value="true">Part object</MenuItem>
            </Select>
            <label className="calendar__wrapper">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Date"
                onBlur={() => (!startDate || !endDate) && setDatepickerRangeError(true)}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                showIcon
                onCalendarOpen={() => setDatepickerRangeError(false)}
                icon={<img src={Calendar} alt="calendar" />}
              />
            </label>
            <p style={{ color: '#e488cc' }}>{datepickerRangeError && 'Please select full date range'}</p>
          </div>

          <div className="orders__table">
            <div className="table__container">
              {loadingTable ? (
                <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
              ) : orders.length > 0 ? (
                <table className="table">
                  {/* <tr>
              <th>Location</th>
              <th>Size</th>
              <th>Rooms</th>
              <th>Bathrooms</th>
              <th>Date and Time</th>
              <th>Type</th>
              <th>Condition</th>
              <th>Price</th>
              <th>Description</th>
            </tr> */}

                  <tbody>
                    {orders.map((item) => (
                      <tr key={item.id}>
                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          <div className="table__info">
                            <img
                              width="60"
                              height="80"
                              src={
                                item.files.length
                                  ? `https://statepix.freeblock.site/uploads/objects/${item.files[0]}`
                                  : Card
                              }
                              alt="card"
                            />

                            <div className="table__info-texts">
                              <p className="table__info-address">
                                {item.address.city}, {item.address.house} {item.address.street}
                              </p>
                              <p className="table__info-name">{item.name}</p>
                            </div>
                          </div>
                        </td>
                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          <div className="card__advantages">
                            <div className="card__advantage">
                              <img
                                className="card__advantage-icon"
                                width="24"
                                height="24"
                                src={Advantage1}
                                alt="icon"
                              />

                              <div className="card__advantage-texts">
                                <p className="card__advantage-text">
                                  {measureSquareConvert(context, item.area)}
                                  <sup>2</sup>
                                </p>
                              </div>
                            </div>

                            <div className="card__advantage">
                              <img
                                className="card__advantage-icon"
                                width="24"
                                height="24"
                                src={Advantage2}
                                alt="icon"
                              />

                              <div className="card__advantage-texts">
                                <p className="card__advantage-text">{item.numberOfBedrooms}</p>
                              </div>
                            </div>

                            <div className="card__advantage">
                              <img
                                className="card__advantage-icon"
                                width="24"
                                height="24"
                                src={Advantage3}
                                alt="icon"
                              />

                              <div className="card__advantage-texts">
                                <p className="card__advantage-text">{item.numberOfBathrooms}</p>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          <div className="table__dates">
                            <p className="table__date">
                              {moment(Number(item.stockInfo.createdAt)).format('DD.MM.YY, hh:mm')}
                            </p>
                          </div>
                        </td>
                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          Selling ({item.stockInfo.isPartialSale ? 'by parts' : 'full'})
                        </td>
                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          {measureSquareConvert(context, item.partArea ?? item.area)}
                          <sup>2</sup>
                        </td>
                        <td onClick={() => navigate(`/objects/${item.id}`)}>
                          {(item.partPrice ?? item.price).toLocaleString()} USDT
                        </td>
                        <td>
                          <div className="table__actions">
                            <button onClick={() => setEditModal(item.id)} type="button" className="table__btn">
                              <img width="20" height="20" src={Create} alt="icon" />
                            </button>

                            <button onClick={() => setDeleteModal(item.id)} type="button" className="table__btn">
                              <img width="20" height="20" src={Trash} alt="icon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="table__no-objects-text">You have no orders...</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${(editModal || deleteModal) && 'show'}`} id="edit2">
        <div className="modal-content">
          <div className="modal__form modal__form--mini">
            <div className="modal__head modal__head--left">
              <h2 className="modal__title">
                {editModal
                  ? orders.find((item) => item.id === editModal)?.name
                  : orders.find((item) => item.id === deleteModal)?.name}
              </h2>

              {!!editModal && (
                <TextField
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  variant="outlined"
                  type="number"
                  size="small"
                  placeholder="Enter amount"
                  style={{ width: '100%' }}
                  label="Price"
                />
              )}

              {!!editModal && (
                <RadioGroup
                  onChange={(e) => setSellingByParts(e.target.value)}
                  value={sellingByParts}
                  defaultValue={false}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="part" control={<Radio />} label="Partial sale" />
                  <FormControlLabel value="full" control={<Radio />} label="Full object sale" />
                </RadioGroup>
              )}

              {!!deleteModal && <p className="modal__text">Are you sure you want to remove this order from sale?</p>}

              <button
                onClick={() => {
                  setEditModal(0)
                  setDeleteModal(0)
                }}
                className="close-modal-btn"
              >
                <img src={CloseModal} alt="close" />
              </button>
            </div>

            <div className="modal__footer">
              {loading ? (
                <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <button
                  onClick={() => {
                    editModal ? handleOrderEdit(editModal) : handleOrderDelete(deleteModal)
                  }}
                  type="submit"
                  className="modal__btn button btn"
                >
                  {editModal ? 'Edit' : 'Remove from sale'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${loading && 'initiate'} ${(editSuccess || deleteSuccess) && 'show'}`} id="success2">
        <div className="modal-content">
          <div className="modal__form modal__form--mini">
            <div className="modal__head">
              <div className="modal__logo">
                <img width="100" height="21" src={LogoBlack} alt="logo" />
              </div>

              <img className="modal__check-pic" width="160" height="160" src={Completed} alt="check" />

              <h2 className="modal__title">
                {editSuccess
                  ? 'Object selling information has been successfully updated'
                  : 'Object has been removed from sale'}
              </h2>

              <button
                onClick={() => {
                  setEditSuccess(false)
                  setDeleteSuccess(false)
                }}
                className="close-modal-btn"
              >
                <img src={CloseModal} alt="close" />
              </button>
            </div>

            <div className="modal__footer">
              <button
                onClick={() => {
                  setEditSuccess(false)
                  setDeleteSuccess(false)
                }}
                className="modal__btn button btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertText}
        autoHideDuration={3000}
        ContentProps={{
          sx: {
            backgroundColor: '#e488cc',
            color: '#fff',
          },
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            borderRadius: '10px',
            marginTop: '75px',
          },
        }}
      />
    </section>
  ) : (
    <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
  )
}

export default Orders
