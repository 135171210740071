import Calendar from '../assets/img/icons/datepicker.svg'

import { useContext, useEffect, useState } from 'react'
import TableCard from '../assets/img/table-card.png'

import DatePicker from 'react-datepicker'
import { CircularProgress, MenuItem, Select } from '@mui/material'
import { UserContext } from '../hooks/context'
import axios from 'axios'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import moment from 'moment'
import measureSquareConvert from '../hooks/measureSquareConvert'
import { useNavigate } from 'react-router-dom'

function TransactionsList({ filtersOn }: { filtersOn?: boolean }) {
  const { context, setContext } = useContext(UserContext)
  const navigate = useNavigate()
  const [tab, setTab] = useState('tokens')
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [transactionObjectList, setTransactionObjectList] = useState<any[]>([])
  const [transactionTokenList, setTransactionTokenList] = useState<any[]>([])
  const [objectType, setObjectType] = useState<string>('full, partial')
  const [objectAction, setObjectAction] = useState<string>('purchase, sale')
  const [objectToken, setObjectToken] = useState<string>('')
  const [objectNetwork, setObjectNetwork] = useState<string>('')
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [startDate, endDate] = dateRange
  const [datepickerRangeError, setDatepickerRangeError] = useState<boolean>(false)

  useEffect(() => {
    if (tab === 'objects' && (!startDate || (startDate && endDate))) {
      setLoadingTable(true)
      axios({
        url: 'https://api.statepix.io/graphql',
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer ' + context.accessToken,
        },
        method: 'post',
        data: {
          query: `
          query Orders {
            orders (filter: { objectTypes: [${objectType}], orderTypes: [${objectAction}]${
            startDate && endDate
              ? `, period: { from: "${moment(startDate).format('YYYY-MM-DD')}", to: "${moment(endDate).format(
                  'YYYY-MM-DD'
                )}" }`
              : ''
          } }) {
            object {
              id
              name
              files
              address {
                city
                street
                house
              }
              numberOfBedrooms
              numberOfBathrooms
            }
            id
            objectType
            status
            statusHistory {
              status
              time
            }
            price
            area
            percentage
            buyerId
            sellerId
            objectId
            createdAt
            updatedAt
          }
        }
        `,
        },
      })
        .then((result) => {
          if (result.data.data === null) {
            if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
              handleTokenRefresh(setContext)
            } else {
              alert(result.data.errors?.[0].message)
            }
          } else {
            setTransactionObjectList(result.data.data.orders)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => setLoadingTable(false))
    }
  }, [context.accessToken, setContext, setTransactionObjectList, tab, objectType, objectAction, startDate, endDate])

  return (
    <div className="history">
      <div className="history__inner">
        <div className="history__head">
          <h2 className="history__title">Transactions</h2>

          <div className="history__btns">
            <button
              onClick={() => setTab('tokens')}
              type="button"
              className={`history__btn ${tab === 'tokens' ? 'active' : ''}`}
            >
              TOKENS
            </button>
            <button
              onClick={() => setTab('objects')}
              type="button"
              className={`history__btn ${tab === 'objects' ? 'active' : ''}`}
            >
              OBJECTS
            </button>
          </div>
        </div>
        {filtersOn && (
          <div className="orders__filters">
            {tab === 'objects' && (
              <Select
                size="small"
                value={objectType}
                onChange={(e) => {
                  setObjectType(e.target.value)
                }}
                style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                displayEmpty
              >
                <MenuItem value="full, partial">All</MenuItem>

                <MenuItem value="full">Full object</MenuItem>
                <MenuItem value="partial">Part object</MenuItem>
              </Select>
            )}
            {tab === 'objects' && (
              <Select
                size="small"
                value={objectAction}
                onChange={(e) => {
                  setObjectAction(e.target.value)
                }}
                style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                displayEmpty
              >
                <MenuItem value="purchase, sale">All</MenuItem>

                <MenuItem value="purchase">Buy object</MenuItem>
                <MenuItem value="sale">Sell object</MenuItem>
              </Select>
            )}
            {tab === 'tokens' && (
              <Select
                size="small"
                value={objectToken}
                onChange={(e) => {
                  setObjectToken(e.target.value)
                }}
                style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>

                <MenuItem value="USDT">USDT</MenuItem>
                <MenuItem value="USDC">USDC</MenuItem>
                <MenuItem value="DAI">DAI</MenuItem>
              </Select>
            )}

            {tab === 'tokens' && (
              <Select
                size="small"
                value={objectNetwork}
                onChange={(e) => {
                  setObjectNetwork(e.target.value)
                }}
                style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>

                <MenuItem value="trc20">TRC-20</MenuItem>
                <MenuItem value="erc20">ERC-20</MenuItem>
              </Select>
            )}
            <label className="calendar__wrapper">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Date"
                onBlur={() => (!startDate || !endDate) && setDatepickerRangeError(true)}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                showIcon
                icon={<img src={Calendar} alt="calendar" />}
                onCalendarOpen={() => setDatepickerRangeError(false)}
              />
            </label>
            <p style={{ color: '#e488cc' }}>{datepickerRangeError && 'Please select full date range'}</p>
          </div>
        )}
        <div className="history__table">
          <div className="table__container">
            {loadingTable ? (
              <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '100px auto' }} />
            ) : tab === 'tokens' ? (
              transactionTokenList.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Transaction</th>
                      <th>Token</th>
                      <th>Network</th>
                      <th>Sum</th>
                      <th>Price</th>
                      <th className="table__thaed-last">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionTokenList.map((transaction) => (
                      <tr key={transaction.id}>
                        <td>
                          <div className="table__dates">
                            <p className="table__date">{transaction.date}</p>
                            {/* <p className="table__date">12:30</p> */}
                          </div>
                        </td>
                        <td>{transaction.transaction}</td>
                        <td className="table__token">{transaction.token}</td>
                        <td className="table__network">{transaction.network}</td>
                        <td>{transaction.sum.toLocaleString('fr-FR')}</td>
                        <td>
                          {transaction.price.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </td>
                        <td>
                          <div className={`table__status table__status--${transaction.status}`}>
                            {transaction.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="table__no-objects-text">You have no tokens transactions...</div>
              )
            ) : transactionObjectList.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>OBJECT</th>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>type of ownership</th>
                    <th>Area</th>
                    <th>Token</th>
                    <th>Price</th>
                    <th className="table__thaed-last">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionObjectList.map((transaction) => (
                    <tr onClick={() => navigate(`/objects/${transaction.object.id}`)} key={transaction.id}>
                      <td>
                        <div className="table__info">
                          <img
                            width="60"
                            height="80"
                            src={
                              transaction.object.files.length
                                ? `https://statepix.freeblock.site/uploads/objects/${transaction.object.files[0]}`
                                : TableCard
                            }
                            alt="card"
                          />

                          <div className="table__info-texts">
                            <p className="table__info-address">
                              {transaction.object.address.city}, {transaction.object.address.house}{' '}
                              {transaction.object.address.street}
                            </p>
                            <p className="table__info-name">{transaction.object.name}</p>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="table__dates">
                          <p className="table__date">
                            {moment(Number(transaction.createdAt)).format('DD.MM.YY, hh:mm')}
                          </p>
                        </div>
                      </td>
                      <td>{transaction.buyerId === context.currentUser.id ? 'Buy' : 'Sell'}</td>
                      <td>
                        {transaction.objectType}
                        <span className="table__percent">
                          {transaction.objectType === 'partial' && `(${transaction.percentage.toFixed(0)}%)`}
                        </span>
                      </td>
                      <td>
                        {measureSquareConvert(context, transaction.area)}
                        <sup>2</sup>
                      </td>
                      <td className="table__token">USDT</td>
                      {/* <td className="table__network">{transaction.network}</td> */}
                      <td>{transaction.price.toLocaleString('fr-FR')}</td>
                      {/* <td>
                        {transaction.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </td> */}
                      <td>
                        <div className={`table__status table__status--${transaction.status}`}>{transaction.status}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="table__no-objects-text">You have no objects transactions...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionsList
