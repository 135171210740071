import Total1 from '../assets/img/icons/objects-total-1.svg'
import Total2 from '../assets/img/icons/objects-total-2.svg'
import Total3 from '../assets/img/icons/objects-total-3.svg'
import Total4 from '../assets/img/icons/objects-total-4.svg'
import Avatar from '../assets/img/icons/avatar-solid.svg'
import Edit from '../assets/img/icons/edit.svg'
// import Checkmark from '../assets/img/icons/shield-checkmark.svg'
import Statistic1 from '../assets/img/icons/profile-statistic-1.svg'
import Statistic2 from '../assets/img/icons/profile-statistic-2.svg'
import Statistic3 from '../assets/img/icons/profile-statistic-3.svg'

import ObjectsList from '../components/ObjectsList'

import { useContext, useEffect, useState } from 'react'
import { CircularProgress, MenuItem, Select } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import { UserContext } from '../hooks/context'
import measureSquareConvert from '../hooks/measureSquareConvert'
import axios from 'axios'
import handleTokenRefresh from '../hooks/handleTokenRefresh'

function Objects() {
  const { context, setContext } = useContext(UserContext)
  const [objects, setObjects] = useState<any[]>([])
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [loadingStatistics, setLoadingStatistics] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<any>(null)
  const [statisticsYear, setStatisticsYear] = useState<number>(new Date().getFullYear())

  useEffect(() => {
    setLoadingStatistics(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query Statistics {
            statistics(year: ${statisticsYear}) {
              totalObjects
              totalArea
              averageObjectPrice
              objectsBoughtThisYear
              objectsBoughtPreviousYear
              totalCostThisYear
              totalCostPreviousYear
              year
              dividend
              expectedSalesProfit
              totalRevenue
              totalRevenuePreviousYear
              profitAndLoss
              profitAndLossPreviousYear
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          setStatistics(result.data.data.statistics)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingStatistics(false))
  }, [context.accessToken, setContext, statisticsYear])

  const filterObject = {
    area: {
      from: '',
      to: '',
    },
    yearOfConstruction: {
      from: '',
      to: '',
    },
    floor: {
      from: '',
      to: '',
    },
    layout: {
      value: '',
    },
    ceilingHeight: {
      from: '',
      to: '',
    },
    repair: {
      value: '',
    },
    view: {
      value: '',
    },
    parking: {
      value: undefined,
    },
    powerSupply: {
      value: undefined,
    },
    elevator: {
      value: undefined,
    },
    security: {
      value: undefined,
    },
    type: [],
    status: '',
    numberOfRooms: '',
    price: {
      from: '',
      to: '',
    },
  }
  const [filterSelection, setFilterSelection] = useState<any>(filterObject)

  return context.currentUser ? (
    <section className="objects">
      <div className="objects__inner">
        <div style={{ opacity: loadingStatistics ? 0.4 : 1 }} className="objects__totals">
          <div className="objects__total">
            <div className="objects__total-info">
              <p className="objects__total-label">Total revenue</p>

              <div className="objects__total-sum">
                <span>{statistics?.totalRevenue.toLocaleString('en-US')} USDT</span>
                <span
                  className={
                    'objects__total-percent' +
                    ((statistics?.totalRevenue * 100) / statistics?.totalRevenuePreviousYear - 100 < 0
                      ? ' objects__total-percent--down'
                      : '')
                  }
                >
                  {statistics?.totalRevenuePreviousYear
                    ? (statistics?.totalRevenue * 100) / statistics?.totalRevenuePreviousYear - 100 + '%'
                    : ''}
                </span>
              </div>
            </div>
            <div className="objects__total-picture">
              <img className="objects__total-pic" width="23" height="23" src={Total1} alt="icon" />
            </div>
          </div>

          <div className="objects__total">
            <div className="objects__total-info">
              <p className="objects__total-label">Total cost of purchased objects</p>

              <div className="objects__total-sum">
                <span>{statistics?.totalCostThisYear.toLocaleString('en-US')} USDT</span>
                <span
                  className={
                    'objects__total-percent' +
                    ((statistics?.totalCostThisYear * 100) / statistics?.totalCostPreviousYear - 100 < 0
                      ? ' objects__total-percent--down'
                      : '')
                  }
                >
                  {statistics?.totalCostPreviousYear
                    ? ((statistics?.totalCostThisYear * 100) / statistics?.totalCostPreviousYear - 100).toLocaleString(
                        'en-US'
                      ) + '%'
                    : ''}
                </span>
              </div>
            </div>
            <div className="objects__total-picture">
              <img className="objects__total-pic" width="23" height="23" src={Total2} alt="icon" />
            </div>
          </div>

          <div className="objects__total">
            <div className="objects__total-info">
              <p className="objects__total-label">Expected sales profit</p>

              <div className="objects__total-sum">
                <span>{statistics?.expectedSalesProfit.toLocaleString('en-US')} USDT</span>
              </div>
            </div>
            <div className="objects__total-picture">
              <img className="objects__total-pic" width="23" height="23" src={Total3} alt="icon" />
            </div>
          </div>

          <div className="objects__total">
            <div className="objects__total-info">
              <p className="objects__total-label">Average price of a property</p>

              <div className="objects__total-sum">
                <span>{statistics?.averageObjectPrice.toLocaleString('en-US')} USDT</span>
              </div>
            </div>
            <div className="objects__total-picture">
              <img className="objects__total-pic" width="23" height="23" src={Total4} alt="icon" />
            </div>
          </div>
        </div>

        <div style={{ opacity: loadingStatistics ? 0.4 : 1 }} className="objects__middle">
          <div className="objects__graphik">
            <div className="objects__graphik-head">
              <div className="objects__graphik-info">
                <h4 className="objects__graphik-label">
                  <span>PnL </span>
                  <span>
                    {statistics?.profitAndLoss.reduce((a: number, b: number) => a + b, 0).toLocaleString('en-US')} USDT
                  </span>
                </h4>

                <div className="objects__graphik-percent">
                  <span>
                    {statistics?.profitAndLossPreviousYear.reduce((a: number, b: number) => a + b, 0)
                      ? (
                          (statistics?.profitAndLoss.reduce((a: number, b: number) => a + b, 0) * 100) /
                            statistics?.profitAndLossPreviousYear.reduce((a: number, b: number) => a + b, 0) -
                          100
                        ).toLocaleString('en-US') + '%'
                      : ''}{' '}
                  </span>
                  <span className="objects__graphik-percent-year">in {statistics?.year}</span>
                </div>
              </div>

              <div className="objects__graphik-select">
                {/* <button type="button" className="objects__graphik-selected">
                  <span>Last Year</span>
                  <img width="12" height="12" src={ChevronDownOutline} alt="icon" />
                </button> */}
                <Select
                  size="small"
                  value={statisticsYear}
                  onChange={(e) => {
                    setStatisticsYear(Number(e.target.value))
                  }}
                  style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                >
                  <MenuItem value={new Date().getFullYear()}>Current Year</MenuItem>
                  <MenuItem value={new Date().getFullYear() - 1}>Previous Year</MenuItem>
                  <MenuItem value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</MenuItem>
                  <MenuItem value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</MenuItem>
                  <MenuItem value={new Date().getFullYear() - 4}>{new Date().getFullYear() - 4}</MenuItem>
                </Select>
              </div>
            </div>

            <div className="objects__graphik-graph">
              <LineChart
                xAxis={[
                  {
                    scaleType: 'point',
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    hideTooltip: true,
                    disableTicks: true,
                    disableLine: true,
                    tickLabelStyle: {
                      fill: '#CBD5E0',
                    },
                  },
                ]}
                yAxis={[
                  {
                    disableTicks: true,
                    disableLine: true,
                    tickLabelStyle: {
                      fill: '#CBD5E0',
                    },
                  },
                ]}
                series={[
                  {
                    data: statistics?.profitAndLoss ?? [],
                    area: true,
                    showMark: false,
                    color: '#e488cc',
                  },
                ]}
                grid={{ horizontal: true, vertical: false }}
                sx={{
                  ' .MuiChartsGrid-line': { strokeDasharray: '5 5', stroke: '#E2E8F0' },
                  '.MuiAreaElement-root': { fill: 'url(#MyGradient)' },
                }}
              >
                <defs>
                  <linearGradient x1="0" y1="0" x2="0" y2="1" id="MyGradient">
                    <stop offset="15%" stopColor="#CE34D45C" />
                    <stop offset="95%" stopColor="#CE34D400" />
                  </linearGradient>
                </defs>
              </LineChart>
            </div>
          </div>

          <div className="objects__profile">
            <section className="profile">
              <div className="profile__inner">
                <h2 className="profile__title">Profile Information</h2>

                <div className="profile__main">
                  <div className="profile__info">
                    <div className="profile__avatar">
                      <img className="profile__avatar-icon" width="36" height="36" src={Avatar} alt="avatar icon" />
                      <button type="button" className="profile__avatar-change">
                        <img src={Edit} width="12" height="12" alt="icon" />
                      </button>
                    </div>

                    <div className="profile__texts">
                      <h3 className="profile__name">Name</h3>
                      <p className="profile__email">{context.currentUser.email}</p>
                    </div>
                  </div>

                  <ul className="profile__list">
                    <li className="profile__item">
                      <p className="profile__item-text profile__item-text--black">ID: </p>
                      <p className="profile__item-text"> {context.currentUser.id}</p>
                    </li>

                    {/* <li className="profile__item">
                      <p className="profile__item-text profile__item-text--black">Status: </p>
                      <div className="profile__item-verifed profile__item-verifed--green">
                        <span>Verificated</span>
                        <img width="12" height="12" src={Checkmark} alt="icon" />
                      </div>
                    </li> */}

                    <li className="profile__item">
                      <p className="profile__item-text profile__item-text--black">Type: </p>
                      <p className="profile__item-text">Profile</p>
                    </li>

                    {/* <li className="profile__item">
                      <p className="profile__item-text profile__item-text--black">Mobile: </p>
                      <p className="profile__item-text">(44) 123 1234 123</p>
                    </li> */}
                  </ul>
                </div>

                <div className="profile__statistic">
                  <div className="profile__statistic-head">
                    <h3 className="profile__statistic-label">Statistics</h3>
                    <div
                      className={`profile__statistic-obj 
                        ${
                          statistics?.objectsBoughtThisYear - statistics?.objectsBoughtPreviousYear < 0
                            ? 'profile__statistic-obj--down'
                            : ''
                        }
                      `}
                    >
                      <span>
                        ({statistics?.objectsBoughtThisYear - statistics?.objectsBoughtPreviousYear < 0 ? '' : '+'}
                        {statistics?.objectsBoughtThisYear - statistics?.objectsBoughtPreviousYear} objects){' '}
                      </span>
                      than last year
                    </div>
                  </div>

                  <ul className="profile__statistic-list">
                    <li className="profile__statistic-item">
                      <div className="profile__statistic-item-info">
                        <div className="profile__statistic-item-icon">
                          <img width="12" height="12" src={Statistic1} alt="icon" />
                        </div>

                        <p className="profile__statistic-item-label">Objects</p>
                      </div>

                      <div className="profile__statistic-item-footer">
                        <p className="profile__statistic-item-sum">{statistics?.totalObjects}</p>

                        <div className="profile__statistic-item-line">
                          <span style={{ width: '33%' }}></span>
                        </div>
                      </div>
                    </li>

                    <li className="profile__statistic-item">
                      <div className="profile__statistic-item-info">
                        <div className="profile__statistic-item-icon">
                          <img width="12" height="12" src={Statistic2} alt="icon" />
                        </div>

                        <p className="profile__statistic-item-label">
                          Sq.{context.currentUser.preferences.measurementSystem === 'imperial' ? 'feet' : 'meters'}
                        </p>
                      </div>

                      <div className="profile__statistic-item-footer">
                        <p className="profile__statistic-item-sum">
                          {measureSquareConvert(context, statistics?.totalArea)}
                          <sup>2</sup>
                        </p>

                        <div className="profile__statistic-item-line">
                          <span style={{ width: '40%' }}></span>
                        </div>
                      </div>
                    </li>

                    <li className="profile__statistic-item">
                      <div className="profile__statistic-item-info">
                        <div className="profile__statistic-item-icon">
                          <img width="12" height="12" src={Statistic3} alt="icon" />
                        </div>

                        <p className="profile__statistic-item-label">Dividend</p>
                      </div>

                      <div className="profile__statistic-item-footer">
                        <p className="profile__statistic-item-sum">
                          {statistics?.dividend.toLocaleString('en', { style: 'currency', currency: 'USD' })}
                        </p>

                        <div className="profile__statistic-item-line">
                          <span style={{ width: '20%' }}></span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
        {loadingStatistics && (
          <CircularProgress sx={{ color: '#df87f1', position: 'absolute', top: '50%', left: '50%' }} />
        )}
        <ObjectsList
          disableSearch
          findMyObjects
          objects={objects}
          setObjects={setObjects}
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
          filterObject={filterObject}
          setFilterOpen={setFilterOpen}
          filterOpen={filterOpen}
        />
      </div>
    </section>
  ) : (
    <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
  )
}

export default Objects
