import axios from 'axios'

const handleTokenRefresh = async (setContext: any) => {
  const refreshToken = localStorage.getItem('StatePix_refreshToken')
  if (refreshToken !== null) {
    axios
      .get('https://api.statepix.io/api/v1/auth/refresh-tokens', {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          accept: 'application/json',
        },
      })
      .then((response) => {
        const newAccessToken = response.data.accessToken
        const newRefreshToken = response.data.refreshToken
        // Update localStorage with the new refresh token
        localStorage.setItem('StatePix_refreshToken', newRefreshToken)

        // Update Axios headers with the new access token
        setContext({ accessToken: newAccessToken })

        // Update localStorage with the new access token
        localStorage.setItem('StatePix_accessToken', newAccessToken)
      })
      .catch((error) => {
        setContext((prev: any) => {
          if (prev.accessToken) {
            return {}
          } else {
            return prev
          }
        })
        localStorage.removeItem('StatePix_refreshToken')
        localStorage.removeItem('StatePix_accessToken')
      })
  } else {
    setContext((prev: any) => {
      if (prev.accessToken) {
        return {}
      } else {
        return prev
      }
    })
    localStorage.removeItem('StatePix_accessToken')
  }
}

export default handleTokenRefresh
