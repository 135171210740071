import { useContext, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { UserContext } from '../hooks/context'
import ObjectsList from '../components/ObjectsList'

function Favorite() {
  const { context } = useContext(UserContext)
  const [objects, setObjects] = useState<any[]>([])
  const filterObject = {
    area: {
      from: '',
      to: '',
    },
    yearOfConstruction: {
      from: '',
      to: '',
    },
    floor: {
      from: '',
      to: '',
    },
    layout: {
      value: '',
    },
    ceilingHeight: {
      from: '',
      to: '',
    },
    repair: {
      value: '',
    },
    view: {
      value: '',
    },
    parking: {
      value: undefined,
    },
    powerSupply: {
      value: undefined,
    },
    elevator: {
      value: undefined,
    },
    security: {
      value: undefined,
    },
    type: [],
    status: '',
    numberOfRooms: '',
    price: {
      from: '',
      to: '',
    },
  }
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [filterSelection, setFilterSelection] = useState<any>(filterObject)

  return context.currentUser ? (
    <ObjectsList
      findFavoriteObjects
      disableSearch
      objects={objects}
      setObjects={setObjects}
      filterSelection={filterSelection}
      setFilterSelection={setFilterSelection}
      filterObject={filterObject}
      setFilterOpen={setFilterOpen}
      filterOpen={filterOpen}
    />
  ) : (
    <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
  )
}

export default Favorite
