import './styles/style.scss'

import Header from './components/Header'
import Aside from './components/Aside'
import Footer from './components/Footer'

import Home from './pages/Home'
import Objects from './pages/MyObjects'
import Orders from './pages/Orders'
import Object from './pages/Object'
import Wallet from './pages/Wallet'
import History from './pages/History'
import Favorite from './pages/Favorite'
import Registration from './pages/Registration'
import Login from './pages/Login'
import Recovery from './pages/Recovery'

import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { UserContext } from './hooks/context'
import VerificationCode from './pages/VerificationCode'
import ObjectsSearch from './pages/ObjectsSearch'
import handleTokenRefresh from './hooks/handleTokenRefresh'
import axios from 'axios'
import Settings from './pages/Settings'
import MfaCode from './pages/MfaCode'
import P2P from './pages/P2P'
import Forgot from './pages/Forgot'

function LayoutHeader() {
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight || 0
  return (
    <div>
      <Header />
      <div className="inner-one" style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <div className="content-white">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
function LayoutHeaderAside() {
  const location = useLocation()
  const { pathname } = location
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight || 0

  const breadcrumbs = useBreadcrumbs()

  return (
    <div>
      <Header />
      <div className="inner" style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <Aside pathname={pathname} />
        <div className="content">
          <div className="breadcrumbs">
            <ul className="breadcrumbs__list">
              {breadcrumbs.map(({ breadcrumb, match }, index) => (
                <li key={index} className={`breadcrumbs__item`}>
                  <Link className="breadcrumbs__link" to={match.pathname}>
                    {breadcrumb}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <h1 className="titleMain">{breadcrumbs[breadcrumbs.length - 1].breadcrumb}</h1>

          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}

function LayoutFullscreen() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  const { pathname } = useLocation()
  const [context, setContext] = useState(
    localStorage.getItem('StatePix_accessToken') ? { accessToken: localStorage.getItem('StatePix_accessToken') } : {}
  )

  useEffect(() => {
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query CurrentUser {
            currentUser {
              id
              email
              phoneNumber
              lastLogin
              createdAt
              updatedAt
              mfaEnabled
              preferences {
                language
                measurementSystem
                theme
              }
            }
          }`,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          setContext((prev) => ({ ...prev, currentUser: result.data.data?.currentUser }))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [context.accessToken, setContext])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <UserContext.Provider value={{ context, setContext }}>
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route element={<LayoutHeader />}>
          <Route path="/home" element={<Home />} />
          <Route path="/institutional-investors" element={<div>For institutional investors</div>} />
          <Route path="/private-investors" element={<div>For private investors</div>} />
          <Route path="/personal-use" element={<div>For personal use</div>} />
          <Route path="/realtors" element={<div>For realtors</div>} />
          <Route path="/technology" element={<div>Technology</div>} />
          <Route path="/legal" element={<div>Legal security</div>} />
          <Route path="/cryptographic" element={<div>Cryptographic security</div>} />
          <Route path="/about" element={<div>About us</div>} />
          <Route path="/documents" element={<div>Documents</div>} />
          <Route path="/contacts" element={<div>Contacts</div>} />
          <Route path="/partners" element={<div>Partners</div>} />
        </Route>
        <Route element={<LayoutFullscreen />}>
          <Route path="/objects" element={<ObjectsSearch />} />
          <Route path="/shared-objects" element={<ObjectsSearch shared />} />
          <Route path="/p2p" element={<P2P />} />
          {!context.accessToken && <Route path="/objects/:objectId" element={<Object />} />}
        </Route>
        <Route element={context.accessToken ? <LayoutHeaderAside /> : <Navigate replace to="/login" />}>
          <Route path="/my-objects" element={<Objects />} />
          {context.accessToken && <Route path="/objects/:objectId" element={<Object />} />}
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/history" element={<History />} />
          <Route path="/favorite" element={<Favorite />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route
          path="/register"
          element={context.accessToken ? <Navigate replace to="/my-objects" /> : <Registration />}
        />
        <Route index path="/login" element={context.accessToken ? <Navigate replace to="/my-objects" /> : <Login />} />
        <Route
          path="/verification"
          element={context.accessToken ? <Navigate replace to="/my-objects" /> : <VerificationCode />}
        />
        <Route path="/mfacode" element={context.accessToken ? <Navigate replace to="/my-objects" /> : <MfaCode />} />
        <Route path="/recovery" element={context.accessToken ? <Navigate replace to="/my-objects" /> : <Recovery />} />
        <Route path="/forgot" element={context.accessToken ? <Navigate replace to="/my-objects" /> : <Forgot />} />
      </Routes>
    </UserContext.Provider>
  )
}

export default App
