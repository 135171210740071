import Logo from '../assets/img/logo.svg'
import Mail from '../assets/img/icons/registration-mail.svg'
import Check from '../assets/img/icons/settings-check.svg'

import { Link } from 'react-router-dom'
import { useState } from 'react'

import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

function Recovery() {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')

  const sendRecoveryLink = () => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
      },
      method: 'post',
      data: {
        query: `
            mutation ResetPassword {
              resetPassword(email: "${email}") {
                success
                message
              }
            }
            `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          setErrorText(result.data.errors?.[0].message)
        } else if (result.data.data?.resetPassword.success) {
          setEmailSent(true)
        } else {
          setErrorText(result.data.data?.resetPassword.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="wrapper wrapper--center wrapper--gradient">
      <div className="registration">
        <div className="registration__inner">
          <Link className="registration__logo" to="/home">
            <img className="registration__logo-img" width="131" height="28" src={Logo} alt="logo img" />
          </Link>

          <div className="registration__head">
            <h1 className="registration__title">{emailSent ? 'Check your mailbox' : 'Password Recovery'}</h1>
            {emailSent && <img src={Check} style={{ width: '148px', margin: 'auto auto' }} alt="check" />}
            <p className="registration__text">
              {emailSent
                ? 'We have sent a recovery link to your email'
                : 'Please, provide the email address associated with your account. We will send you a recovery link.'}
            </p>
          </div>

          {!emailSent && (
            <div className="registration__main">
              <div className="registration__rows">
                <div className="registration__row">
                  <label className="registration__label">email</label>

                  <div className="registration__input-container">
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value)
                        setErrorText('')
                      }}
                      value={email}
                      className="registration__input"
                      type="email"
                      placeholder="john_johnson@gmail.com"
                    />
                    <img className="registration__input-icon" width="20" height="20" src={Mail} alt="icon" />
                  </div>
                </div>
                {errorText && (
                  <div className="registration__row">
                    <div style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>
                      {errorText.split('_').join(' ')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!emailSent && (
            <div className="registration__footer">
              {loading ? (
                <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <button
                  disabled={!isEmail(email)}
                  className={`registration__btn btn ${!isEmail(email) ? 'disabled' : ''}`}
                  onClick={() => sendRecoveryLink()}
                >
                  SEND RECOVERY LINK
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Recovery
