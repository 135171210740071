import Logo from '../assets/img/logo.svg'
import Lock from '../assets/img/icons/registration-lock.svg'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import validator from 'validator'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

function Forgot() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [code] = useState(searchParams.get('code') || '')
  const [password, setPassword] = useState('')
  const [passwordRep, setPasswordRep] = useState('')
  const [loading, setLoading] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [validationError, setValidationError] = useState<any>({
    password: '',
    passwordRep: '',
  })

  useEffect(() => {
    if (passwordChanged) {
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
  }, [passwordChanged, navigate])

  const setNewPassword = () => {
    setLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
      },
      method: 'post',
      data: {
        query: `
            mutation ConfirmResetPassword {
              confirmResetPassword(code: "${code}", newPassword: "${password}") {
                success
                message
              }
            }
            `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          alert(result.data.errors?.[0].message)
        } else if (result.data.data?.confirmResetPassword.success) {
          setPasswordChanged(true)
        } else {
          alert(result.data.data?.confirmResetPassword.message)
          result.data.data?.confirmResetPassword.message === 'Code expired' && navigate('/recovery')
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="wrapper wrapper--center wrapper--gradient">
      <div className="registration">
        <div className="registration__inner">
          <Link className="registration__logo" to="/home">
            <img className="registration__logo-img" width="131" height="28" src={Logo} alt="logo img" />
          </Link>

          <div className="registration__head">
            <h1 className="registration__title">
              {passwordChanged ? 'Password was successfully changed' : 'Password Recovery'}
            </h1>
            <p className="registration__text">
              {passwordChanged ? 'You will be redirected to the login page in 3s...' : 'Set your new password'}
            </p>
          </div>

          {!passwordChanged && code && (
            <div className="registration__main">
              <div className="registration__rows">
                <div className="registration__row">
                  <label className="registration__label">NEW PASSWORD</label>

                  <div className="registration__input-container">
                    <input
                      onBlur={() => {
                        if (!validator.isStrongPassword(password)) {
                          setValidationError({
                            ...validationError,
                            password:
                              'Password must be at least 8 characters, contain one uppercase letter, one lowercase letter, one number and one special character',
                          })
                        } else {
                          setValidationError({ ...validationError, password: '' })
                        }
                      }}
                      style={{ border: validationError.password ? '1px solid red' : '' }}
                      onChange={(e) => {
                        setPassword(e.target.value.trim())
                        validator.isStrongPassword(e.target.value.trim()) &&
                          setValidationError({ ...validationError, password: '' })
                      }}
                      value={password}
                      className="registration__input"
                      type="password"
                      placeholder="********"
                    />
                    <img className="registration__input-icon" width="20" height="20" src={Lock} alt="icon" />
                  </div>
                  <label style={{ color: 'red' }} className="registration__label">
                    {validationError.password}
                  </label>
                </div>

                <div className="registration__row">
                  <label className="registration__label">REpeat NEW PASSWORD</label>

                  <div className="registration__input-container">
                    <input
                      style={{ border: validationError.passwordRep ? '1px solid red' : '' }}
                      onBlur={() => {
                        if (password !== passwordRep) {
                          setValidationError({ ...validationError, passwordRep: 'Passwords do not match' })
                        } else {
                          setValidationError({ ...validationError, passwordRep: '' })
                        }
                      }}
                      onChange={(e) => {
                        setPasswordRep(e.target.value.trim())
                        password === e.target.value.trim() &&
                          setValidationError({ ...validationError, passwordRep: '' })
                      }}
                      value={passwordRep}
                      className="registration__input"
                      type="password"
                      placeholder="********"
                    />
                    <img className="registration__input-icon" width="20" height="20" src={Lock} alt="icon" />
                  </div>
                  <label style={{ color: 'red' }} className="registration__label">
                    {validationError.passwordRep}
                  </label>
                </div>
              </div>
            </div>
          )}

          {!passwordChanged && code && (
            <div className="registration__footer">
              {loading ? (
                <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <button
                  disabled={!validator.isStrongPassword(password) || password !== passwordRep || !code}
                  className={`registration__btn btn ${
                    !validator.isStrongPassword(password) || password !== passwordRep || !code ? 'disabled' : ''
                  }`}
                  onClick={() => setNewPassword()}
                >
                  CONFIRM
                </button>
              )}
            </div>
          )}
          {passwordChanged && (
            <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Forgot
