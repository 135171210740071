import { useContext } from 'react'
import Card from '../assets/img/card.jpg'
import Advantage1 from '../assets/img/icons/card-advantage-1.svg'
import Advantage2 from '../assets/img/icons/card-advantage-2.svg'
import Advantage3 from '../assets/img/icons/card-advantage-3.svg'
import measureSquareConvert from '../hooks/measureSquareConvert'

import { Link } from 'react-router-dom'
import { UserContext } from '../hooks/context'

function RelatedObjects({ list }: { list: any[] }) {
  const { context } = useContext(UserContext)
  return (
    <div className="releted-objects">
      <div className="releted-objects__inner">
        <h3 className="releted-objects__title">Related Objects</h3>

        <ul className="releted-objects__list cards">
          {list.map((item) => (
            <Link key={item.id} to={`/objects/${item.id}`}>
              <li className="card">
                <div className="card__picture">
                  <div className="card__badge">{item.type}</div>
                  <img className="card__pic" width="294" height="300" src={Card} alt="object_picture" />
                </div>

                <div className="card__footer">
                  <div className="card__info">
                    <p className="card__location">
                      {item.address.city}, {item.address.house} {item.address.street}
                    </p>
                    <h4 className="card__title">{item.name}</h4>
                  </div>

                  <div className="card__advantages">
                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage1} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">
                          {measureSquareConvert(context, item.area)}
                          <sup>2</sup>
                        </p>
                      </div>
                    </div>

                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage2} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">{item.numberOfBedrooms}</p>
                      </div>
                    </div>

                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage3} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">{item.numberOfBathrooms}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default RelatedObjects
