import Logo from '../assets/img/logo.svg'
import Union from '../assets/img/icons/Union.svg'
import MageX from '../assets/img/icons/mage_x.svg'
import Instagram from '../assets/img/icons/ri_instagram-fill.svg'
import Telegram from '../assets/img/icons/mingcute_telegram-fill.svg'
import Facebook from '../assets/img/icons/ri_facebook-fill.svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__top">
          <div className="footer__logo">
            <Link className="footer__logo-icon" to="/home">
              <img src={Logo} width="100" height="21" alt="logo" />
            </Link>
            <p className="footer__logo-descr">
              Lorem ipsum dolor sit amet consectetur. Sed nullam tempus amet neque pretium diam et. Nisl magna pretium
              facilisis nec donec. Nascetur consequ
            </p>
          </div>
          <nav className="footer__nav">
            <ul className="footer__list">
              <li className="footer__item footer__item-head">Products</li>
              <li className="footer__item">
                <Link className="footer__link" to="/objects">
                  Buying real estate
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/shared-objects">
                  Shared purchase of real estate
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/p2p">
                  P2P
                </Link>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item-head">Company</li>
              <li className="footer__item">
                <Link className="footer__link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/documents">
                  Documnets
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/contacts">
                  Contacts
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/partners">
                  Partners
                </Link>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item-head">Solutions</li>
              <li className="footer__item">
                <Link className="footer__link" to="/institutional-investors">
                  For Institutional Investors
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/private-investors">
                  For Private Investors
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/personal-use">
                  For Personal Use
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/realtors">
                  For Rieltors
                </Link>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item-head">Security </li>
              <li className="footer__item">
                <Link className="footer__link" to="/legal">
                  Legal security
                </Link>
              </li>
              <li className="footer__item">
                <Link className="footer__link" to="/cryptographic">
                  Сryptographic security
                </Link>
              </li>
            </ul>
          </nav>

          <div className="footer__block">
            <img className="footer__block-icon" width="15" height="21" src={Union} alt="Union icon" />
            <h4 className="footer__block-label">StatePix Technology</h4>
            <p className="footer__block-text">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
        </div>

        <div className="footer__bottom">
          <p className="footer__copy">@ 2024. All rights reserved</p>

          <ul className="footer__socilas">
            <li className="footer__social">
              <Link className="footer__social-link" to="/home">
                <img src={MageX} width="24" height="24" alt="social icon" />
              </Link>
            </li>

            <li className="footer__social">
              <Link className="footer__social-link" to="/home">
                <img src={Instagram} width="24" height="24" alt="social icon" />
              </Link>
            </li>

            <li className="footer__social">
              <Link className="footer__social-link" to="/home">
                <img src={Telegram} width="24" height="24" alt="social icon" />
              </Link>
            </li>

            <li className="footer__social">
              <Link className="footer__social-link" to="/home">
                <img src={Facebook} width="24" height="24" alt="social icon" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
