import Logo from '../assets/img/logo.svg'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import axios from 'axios'
import VerificationInput from 'react-verification-input'

function VerificationCode() {
  const [code, setCode] = useState('')
  const { state } = useLocation()
  const email = state?.email || ''
  const navigate = useNavigate()

  const verifyHandler = () => {
    axios
      .post('https://api.statepix.io/api/v1/auth/verify', {
        email,
        verificationCode: code,
      })
      .then((res) => {
        if (res.status === 201) {
          navigate(`/login`)
        }
      })
      .catch((err) => {
        alert(err.response.data.message)
      })
  }

  return (
    <div className="wrapper wrapper--center wrapper--gradient">
      <div className="registration">
        <div className="registration__inner">
          <Link className="registration__logo" to="/home">
            <img className="registration__logo-img" width="131" height="28" src={Logo} alt="logo img" />
          </Link>

          <div className="registration__head">
            <h1 className="registration__title">Verify your email</h1>
            <p className="registration__text">TEST MODE: Enter code 111111</p>
          </div>

          <div className="registration__main">
            <div className="registration__rows">
              <div className="registration__row">
                <VerificationInput
                  placeholder=""
                  value={code}
                  onChange={(value) => setCode(value)}
                  length={6}
                  validChars="0-9"
                  inputProps={{ inputMode: 'numeric', autoComplete: 'one-time-code' }}
                  classNames={{
                    container: 'settings__inputs-list',
                    character: 'settings__inputs-input',
                    characterInactive: 'settings__inputs-input--inactive',
                    characterSelected: 'settings__inputs-input--selected',
                    characterFilled: 'settings__inputs-input--filled',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="registration__footer">
            <button
              disabled={code.length < 6}
              className={`registration__btn btn ${code.length < 6 ? 'disabled' : ''}`}
              onClick={verifyHandler}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerificationCode
