import Marker from '../assets/img/icons/marker.svg'

import { useContext, useEffect, useState } from 'react'
import { CircularProgress, MenuItem, Select, Slider, TextField } from '@mui/material'
import { AdvancedMarker, APIProvider, ControlPosition, Map, MapControl } from '@vis.gl/react-google-maps'
import ObjectsList from '../components/ObjectsList'
import { UserContext } from '../hooks/context'
import axios from 'axios'
import handleTokenRefresh from '../hooks/handleTokenRefresh'

const Markers = ({ points }: { points: any[] }) => {
  return (
    <>
      {points.map((point) => (
        <AdvancedMarker key={point.id} position={{ lat: point.coordinates.lat, lng: point.coordinates.lon }}>
          <img src={Marker} alt="marker" />
        </AdvancedMarker>
      ))}
    </>
  )
}

function ObjectsSearch({ shared }: any) {
  const { context, setContext } = useContext(UserContext)
  const [objects, setObjects] = useState<any[]>([])
  const filterObject = {
    area: {
      from: '',
      to: '',
    },
    yearOfConstruction: {
      from: '',
      to: '',
    },
    floor: {
      from: '',
      to: '',
    },
    layout: {
      value: '',
    },
    ceilingHeight: {
      from: '',
      to: '',
    },
    repair: {
      value: '',
    },
    view: {
      value: '',
    },
    parking: {
      value: undefined,
    },
    powerSupply: {
      value: undefined,
    },
    elevator: {
      value: undefined,
    },
    security: {
      value: undefined,
    },
    type: [],
    status: '',
    numberOfRooms: '',
    price: {
      from: '',
      to: '',
    },
  }
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [filterSelection, setFilterSelection] = useState<any>(filterObject)
  const [searchFilters, setSearchFilters] = useState<any[]>([])

  useEffect(() => {
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query SearchFilters {
            searchFilters {
              propName
              type
              range {
                min
                max
              }
              values
            }
          }`,
      },
    })
      .then((result) => {
        setSearchFilters(result.data.data.searchFilters)
        setFilterSelection((prev: typeof filterObject) => ({
          ...prev,
          price: {
            from: `${result.data.data.searchFilters.find((item: any) => item.propName === 'price').range.min}`,
            to: `${result.data.data.searchFilters.find((item: any) => item.propName === 'price').range.max}`,
          },
          area: {
            from: `${
              context.currentUser?.preferences.measurementSystem === 'imperial'
                ? (
                    result.data.data.searchFilters.find((item: any) => item.propName === 'area').range.min *
                    10.76391041671
                  ).toFixed(0)
                : result.data.data.searchFilters.find((item: any) => item.propName === 'area').range.min
            }`,
            to: `${
              context.currentUser?.preferences.measurementSystem === 'imperial'
                ? (
                    result.data.data.searchFilters.find((item: any) => item.propName === 'area').range.max *
                    10.76391041671
                  ).toFixed(0)
                : result.data.data.searchFilters.find((item: any) => item.propName === 'area').range.max
            }`,
          },
        }))
      })
      .catch((error) => {
        if (error.response?.errorStatus === 401) {
          handleTokenRefresh(setContext)
        }
      })
  }, [context, setContext])

  const handleChangeCheckboxFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilterSelection({ ...filterSelection, type: [...filterSelection.type, event.target.value] })
    } else {
      setFilterSelection({
        ...filterSelection,
        type: filterSelection.type.filter((item: string) => item !== event.target.value),
      })
    }
  }

  const handleChangePriceRangeFilter = (event: Event, newValue: number | number[]) => {
    setFilterSelection({
      ...filterSelection,
      price: { from: `${(newValue as number[])[0]}`, to: `${(newValue as number[])[1]}` },
    })
  }
  const handleChangeAreaRangeFilter = (event: Event, newValue: number | number[]) => {
    setFilterSelection({
      ...filterSelection,
      area: { from: `${(newValue as number[])[0]}`, to: `${(newValue as number[])[1]}` },
    })
  }

  return (
    <div className="fullscreen">
      <div className="filters">
        {searchFilters.length > 0 ? (
          <div className="filters__inner">
            <div className="filters__block">
              <p className="filters__block-label">Object Type </p>

              <ul className="filters__list">
                {searchFilters
                  .find((item: any) => item.propName === 'type')
                  ?.values.map((item: any) => (
                    <li key={item} className="filters__item">
                      <div className="filters__checkbox">
                        <input
                          className="filters__checkbox-input"
                          type="checkbox"
                          value={item}
                          checked={filterSelection.type.includes(item)}
                          onChange={handleChangeCheckboxFilter}
                          id={'checkbox-' + item}
                        />

                        <label className="filters__checkbox-label" htmlFor={'checkbox-' + item}>
                          <div className="filters__checkbox-decor"></div>

                          <p className="filters__checkbox-text">{item}</p>
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="filters__block">
              <p className="filters__block-label">Status</p>

              <div className="filters__select">
                <Select
                  size="small"
                  value={filterSelection.status}
                  fullWidth
                  onChange={(e) => {
                    setFilterSelection({ ...filterSelection, status: e.target.value })
                  }}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="ready">Ready</MenuItem>
                  <MenuItem value="off_plan">Off plan</MenuItem>
                </Select>
              </div>
            </div>

            <div className="filters__block">
              <p className="filters__block-label">Rooms</p>

              <div className="filters__select">
                <Select
                  size="small"
                  value={filterSelection.numberOfRooms}
                  fullWidth
                  onChange={(e) => {
                    setFilterSelection({ ...filterSelection, numberOfRooms: e.target.value })
                  }}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  {Array.from({ length: 10 }, (_, i) => i + 3).map((number) => (
                    <MenuItem key={number} value={String(number)}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className="filters__block">
              <p className="filters__block-label">Price</p>

              <div className="price-input">
                <div className="field">
                  <TextField
                    value={filterSelection.price.from}
                    onChange={(e) =>
                      setFilterSelection({
                        ...filterSelection,
                        price: { ...filterSelection.price, from: e.target.value },
                      })
                    }
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="field">
                  <TextField
                    value={filterSelection.price.to}
                    onChange={(e) =>
                      setFilterSelection({
                        ...filterSelection,
                        price: { ...filterSelection.price, to: e.target.value },
                      })
                    }
                    variant="outlined"
                    type="number"
                  />
                </div>
              </div>
              <Slider
                value={[Number(filterSelection.price.from), Number(filterSelection.price.to)]}
                onChange={handleChangePriceRangeFilter}
                min={searchFilters.find((item) => item.propName === 'price')?.range.min || 0}
                max={searchFilters.find((item) => item.propName === 'price')?.range.max || 0}
              />
              <div className="range-input__sums">
                <div className="range-input__sum-min">
                  ${searchFilters.find((item) => item.propName === 'price')?.range.min || 0}
                </div>
                <div className="range-input__sum-max">
                  ${searchFilters.find((item) => item.propName === 'price')?.range.max || 0}
                </div>
              </div>
            </div>

            <div className="filters__block">
              <p className="filters__block-label">Object Area</p>

              <div className="price-input">
                <div className="field">
                  <TextField
                    value={filterSelection.area.from}
                    onChange={(e) =>
                      setFilterSelection({
                        ...filterSelection,
                        area: { ...filterSelection.area, from: e.target.value },
                      })
                    }
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="field">
                  <TextField
                    value={filterSelection.area.to}
                    onChange={(e) =>
                      setFilterSelection({ ...filterSelection, area: { ...filterSelection.area, to: e.target.value } })
                    }
                    variant="outlined"
                    type="number"
                  />
                </div>
              </div>
              <Slider
                value={[Number(filterSelection.area.from), Number(filterSelection.area.to)]}
                onChange={handleChangeAreaRangeFilter}
                min={
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? Number(
                        (
                          (searchFilters.find((item) => item.propName === 'area')?.range.min || 0) * 10.76391041671
                        ).toFixed(0)
                      )
                    : searchFilters.find((item) => item.propName === 'area')?.range.min || 0
                }
                max={
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? Number(
                        (
                          (searchFilters.find((item) => item.propName === 'area')?.range.max || 0) * 10.76391041671
                        ).toFixed(0)
                      )
                    : searchFilters.find((item) => item.propName === 'area')?.range.max || 0
                }
              />
              <div className="range-input__sums">
                <div className="range-input__sum-min">
                  {context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? Number(
                        (
                          (searchFilters.find((item) => item.propName === 'area')?.range.min || 0) * 10.76391041671
                        ).toFixed(0)
                      )
                    : searchFilters.find((item) => item.propName === 'area')?.range.min || 0}{' '}
                  {context.currentUser?.preferences.measurementSystem === 'imperial' ? 'ft²' : 'm²'}
                </div>
                <div className="range-input__sum-max">
                  {context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? Number(
                        (
                          (searchFilters.find((item) => item.propName === 'area')?.range.max || 0) * 10.76391041671
                        ).toFixed(0)
                      )
                    : searchFilters.find((item) => item.propName === 'area')?.range.max || 0}{' '}
                  {context.currentUser?.preferences.measurementSystem === 'imperial' ? 'ft²' : 'm²'}
                </div>
              </div>
            </div>

            <div className="filters__block">
              <p className="filters__block-label">Floor Number</p>

              <div className="filters__select">
                <Select
                  size="small"
                  value={filterSelection.floor.from}
                  fullWidth
                  onChange={(e) => {
                    setFilterSelection({ ...filterSelection, floor: { from: e.target.value, to: e.target.value } })
                  }}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                    <MenuItem key={number} value={String(number)}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <button onClick={() => setFilterOpen(true)} className="filters__btn">
              MORE FILTERS
            </button>
          </div>
        ) : (
          <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '100px auto' }} />
        )}
      </div>
      <div className="content">
        <div className="buy-object">
          <div className="buy-object__inner">
            <div className="buy-object__map">
              <APIProvider apiKey="AIzaSyC0Eycy7xeMtl_LGFExkrW6aiJb3FFeEQA">
                <Map
                  mapId="1a01564b1f987586"
                  style={{ width: '100%', height: '100%' }}
                  defaultCenter={{ lat: 25.197525, lng: 55.274288 }}
                  defaultZoom={13}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                  fullscreenControl={true}
                  fullscreenControlOptions={{
                    position: ControlPosition.RIGHT_BOTTOM,
                  }}
                >
                  {/* <MapControl position={ControlPosition.RIGHT_BOTTOM}>
                    <button type="button" className="buy-object__fullscreen">
                      <img width="20" height="20" src={Fullscreen} alt="icon" />
                    </button>
                  </MapControl> */}
                  <MapControl position={ControlPosition.TOP_LEFT}>
                    <div className="buy-object__select">
                      <Select
                        displayEmpty
                        variant="outlined"
                        defaultValue="UAE, Dubai"
                        sx={{ backgroundColor: 'white', width: '200px' }}
                      >
                        <MenuItem value="UAE, Dubai">UAE, Dubai</MenuItem>
                        <MenuItem value="UAE, Dubai 2">UAE, Dubai 2</MenuItem>
                      </Select>
                    </div>
                  </MapControl>
                  {objects.length > 0 && <Markers points={objects} />}
                </Map>
              </APIProvider>
            </div>
            <ObjectsList
              objects={objects}
              setObjects={setObjects}
              filterSelection={filterSelection}
              setFilterSelection={setFilterSelection}
              filterObject={filterObject}
              setFilterOpen={setFilterOpen}
              filterOpen={filterOpen}
              shared={shared}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectsSearch
