import Search from '../assets/img//icons/search.svg'
import Sort from '../assets/img/icons/filter-sorting.svg'
import Filter from '../assets/img/icons/filter-filter.svg'
import ViewBlock from '../assets/img/icons/filter-view-block.svg'
import ViewList from '../assets/img/icons/filter-view-list.svg'
import Card from '../assets/img/card.jpg'
import Favorite from '../assets/img/icons/favorite.svg'
import FavoriteFill from '../assets/img/icons/favorite-fill.svg'
import Advantage1 from '../assets/img/icons/card-advantage-1.svg'
import Advantage2 from '../assets/img/icons/card-advantage-2.svg'
import Advantage3 from '../assets/img/icons/card-advantage-3.svg'
import CloseModal from '../assets/img/icons/close-modal.svg'

import handleTokenRefresh from '../hooks/handleTokenRefresh'
import measureSquareConvert from '../hooks/measureSquareConvert'
import measureConvert from '../hooks/measureConvert'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CircularProgress, MenuItem, Pagination, Popover, Select } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../hooks/context'
import axios from 'axios'
import debounce from 'lodash/debounce'
import numeral from 'numeral'

function ObjectsList({
  objects,
  setObjects,
  filterSelection,
  setFilterSelection,
  filterObject,
  filterOpen,
  setFilterOpen,
  disableSearch,
  findMyObjects,
  findFavoriteObjects,
  shared = false,
}: {
  objects: any[]
  setObjects: any
  filterSelection: any
  setFilterSelection: any
  filterObject: any
  filterOpen: boolean
  setFilterOpen: any
  disableSearch?: boolean
  findMyObjects?: boolean
  findFavoriteObjects?: boolean
  shared?: boolean
}) {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { context, setContext } = useContext(UserContext)
  const searchValue = state?.searchValue || ''
  const [sortOpen, setSortOpen] = useState<HTMLButtonElement | null>(null)

  const [showShared, setShowShared] = useState<boolean>(false)
  const [viewType, setViewType] = useState<string>('block')
  const [objectsPerPage, setObjectsPerPage] = useState<number>(10)
  const [searchInput, setSearchInput] = useState<string>(searchValue ? searchValue : '')
  const [sort, setSort] = useState<string>('asc')
  const [sortName, setSortName] = useState<string>('name')
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [favoriteIds, setFavoriteIds] = useState<number[]>([])

  const [loadingTable, setLoadingTable] = useState<boolean>(false)

  const sortList = ['name', 'price', 'area', 'numberOfBedrooms', 'numberOfBathrooms']

  const handleSortOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortOpen(event.currentTarget)
  }

  const handleCloseSort = () => {
    setSortOpen(null)
  }

  useEffect(() => {
    if (context.accessToken) {
      axios({
        url: 'https://api.statepix.io/graphql',
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer ' + context.accessToken,
        },
        method: 'post',
        data: {
          query: `
                query favoriteObjects {
                  favoriteObjects {
                    id
                  }
                }
                `,
        },
      })
        .then((result) => {
          if (result.data.data === null) {
            if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
              handleTokenRefresh(setContext)
            } else {
              alert(result.data.errors?.[0].message)
            }
          } else {
            const arrayOfIds = result.data.data.favoriteObjects.map((obj: any) => obj.id)
            setFavoriteIds(arrayOfIds)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [context.accessToken, setContext])

  useEffect(() => {
    setLoadingTable(true)
    const allObjectsQuery = `
      query objectSearch {
        objectSearch(page: ${page - 1}, recordsPerPage: ${objectsPerPage},
        str: "${searchInput}",
        sortCriteria: { propName: "${sortName}", order: ${sort}}
        criteria: [
          {propName: "isPartialSale", booleanValue: ${showShared || shared}},
        ]
        ) {
          total
          objects {
            stockInfo {
              isPartialSale
              minAllowedArea
            }
            id
            name
            files
            type
            area
            price
            description
            numberOfBedrooms
            numberOfBathrooms
            readinessStatus
            isPart
            partPrice
            partPercentage
            partArea
            userId
            address {
              city
              street
              house
            }
            coordinates {
              lat
              lon
            }
          }
        }
      }
      `
    const myObjectsQuery = `
      query objects {
        objects(sortCriteria: { propName: "${sortName}", order: ${sort}},
        criteria: [
          {propName: "isPart", booleanValue: ${showShared}},
        ]
        ) {
          stockInfo {
            isPartialSale
            minAllowedArea
          }
          id
          name
          files
          type
          area
          price
          description
          numberOfBedrooms
          numberOfBathrooms
          readinessStatus
          isPart
          partPrice
          partPercentage
          partArea
          userId
          address {
            city
            street
            house
          }
          coordinates {
            lat
            lon
          }
        }
      }
      `
    const favoriteObjectsQuery = `
      query favoriteObjects {
        favoriteObjects(sortCriteria: { propName: "${sortName}", order: ${sort}}
        ) {
          stockInfo {
            isPartialSale
            minAllowedArea
          }
          id
          name
          files
          type
          area
          price
          description
          numberOfBedrooms
          numberOfBathrooms
          readinessStatus
          isPart
          partPrice
          partPercentage
          partArea
          userId
          address {
            city
            street
            house
          }
          coordinates {
            lat
            lon
          }
        }
      }
      `
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: findMyObjects ? myObjectsQuery : findFavoriteObjects ? favoriteObjectsQuery : allObjectsQuery,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          !findMyObjects && !findFavoriteObjects && setTotal(result.data.data.objectSearch.total)
          findMyObjects
            ? setObjects(result.data.data.objects)
            : findFavoriteObjects
            ? setObjects(result.data.data.favoriteObjects)
            : setObjects(result.data.data.objectSearch.objects)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingTable(false))
  }, [
    context.accessToken,
    setContext,
    searchInput,
    page,
    objectsPerPage,
    sort,
    sortName,
    setObjects,
    findMyObjects,
    findFavoriteObjects,
    showShared,
    shared,
  ])

  useEffect(() => {
    filterOpen ? document.body.classList.add('locked') : document.body.classList.remove('locked')
  }, [filterOpen])

  const findFilteredObjects = () => {
    setFilterOpen(false)
    setLoadingTable(true)
    const allObjectsQuery = `
      query objectSearch {
        objectSearch(page: ${page - 1}, recordsPerPage: ${objectsPerPage},
        str: "${searchInput}",
        sortCriteria: { propName: "${sortName}", order: ${sort}},
        criteria: [
          {propName: "isPartialSale", booleanValue: ${showShared || shared}},
          ${
            filterSelection.layout.value
              ? `{ propName: "layout", stringValues: "${filterSelection.layout.value}" }`
              : ''
          },
          ${
            filterSelection.area.from && filterSelection.area.to
              ? `{ propName: "area", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.from) / 10.76391041671).toFixed(0)
                    : filterSelection.area.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.to) / 10.76391041671).toFixed(0)
                    : filterSelection.area.to
                } } }`
              : ''
          },
          ${
            filterSelection.yearOfConstruction.from && filterSelection.yearOfConstruction.to
              ? `{ propName: "yearOfConstruction", rangeValues: { min: ${filterSelection.yearOfConstruction.from}, max: ${filterSelection.yearOfConstruction.to} } }`
              : ''
          },
          ${
            filterSelection.floor.from && filterSelection.floor.to
              ? `{ propName: "floor", rangeValues: { min: ${filterSelection.floor.from}, max: ${filterSelection.floor.to} } }`
              : ''
          },
          ${
            filterSelection.ceilingHeight.from && filterSelection.ceilingHeight.to
              ? `{ propName: "ceilingHeight", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.from) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.to) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.to
                } } }`
              : ''
          },
          ${
            filterSelection.repair.value
              ? `{ propName: "repair", stringValues: "${filterSelection.repair.value}" }`
              : ''
          },
          ${filterSelection.view.value ? `{ propName: "view", stringValues: "${filterSelection.view.value}" }` : ''},
          ${
            filterSelection.parking.value !== undefined
              ? `{ propName: "parking", booleanValue: ${filterSelection.parking.value} }`
              : ''
          },
          ${
            filterSelection.powerSupply.value !== undefined
              ? `{ propName: "powerSupply", booleanValue: ${filterSelection.powerSupply.value} }`
              : ''
          },
          ${
            filterSelection.elevator.value !== undefined
              ? `{ propName: "elevator", booleanValue: ${filterSelection.elevator.value} }`
              : ''
          },
          ${
            filterSelection.security.value !== undefined
              ? `{ propName: "security", booleanValue: ${filterSelection.security.value} }`
              : ''
          },
          ${
            filterSelection.type.length
              ? `{ propName: "type", stringValues: [${filterSelection.type.map((type: string) => `"${type}"`)}] }`
              : ''
          },
          ${filterSelection.status ? `{ propName: "readinessStatus", stringValues: "${filterSelection.status}" }` : ''},
          ${
            filterSelection.price.from && filterSelection.price.to
              ? `{ propName: "price", rangeValues: { min: ${filterSelection.price.from}, max: ${filterSelection.price.to} } }`
              : ''
          },
          ${
            filterSelection.numberOfRooms
              ? `{ propName: "numberOfRooms", stringValues: "${filterSelection.numberOfRooms}" }`
              : ''
          }
        ]
        ) {
          total
          objects {
            stockInfo {
              isPartialSale
              minAllowedArea
            }
            id
            name
            files
            type
            area
            numberOfBedrooms
            numberOfBathrooms
            price
            description
            readinessStatus
            isPart
            partPercentage
            partArea
            partPrice
            userId
            address {
              city
              street
              house
            }
            coordinates {
              lat
              lon
            }
          }
        }
      }`
    const myObjectsQuery = `
      query objects {
        objects( sortCriteria: { propName: "${sortName}", order: ${sort}},
        criteria: [
        {propName: "isPart", booleanValue: ${showShared}},
          ${
            ''
            // { propName: "isPart", booleanValue: ${showShared} },
          }
          ${
            filterSelection.layout.value
              ? `{ propName: "layout", stringValues: "${filterSelection.layout.value}" }`
              : ''
          },
          ${
            filterSelection.area.from && filterSelection.area.to
              ? `{ propName: "area", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.from) / 10.76391041671).toFixed(0)
                    : filterSelection.area.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.to) / 10.76391041671).toFixed(0)
                    : filterSelection.area.to
                } } }`
              : ''
          },
          ${
            filterSelection.yearOfConstruction.from && filterSelection.yearOfConstruction.to
              ? `{ propName: "yearOfConstruction", rangeValues: { min: ${filterSelection.yearOfConstruction.from}, max: ${filterSelection.yearOfConstruction.to} } }`
              : ''
          },
          ${
            filterSelection.floor.from && filterSelection.floor.to
              ? `{ propName: "floor", rangeValues: { min: ${filterSelection.floor.from}, max: ${filterSelection.floor.to} } }`
              : ''
          },
          ${
            filterSelection.ceilingHeight.from && filterSelection.ceilingHeight.to
              ? `{ propName: "ceilingHeight", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.from) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.to) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.to
                } } }`
              : ''
          },
          ${
            filterSelection.repair.value
              ? `{ propName: "repair", stringValues: "${filterSelection.repair.value}" }`
              : ''
          },
          ${filterSelection.view.value ? `{ propName: "view", stringValues: "${filterSelection.view.value}" }` : ''},
          ${
            filterSelection.parking.value !== undefined
              ? `{ propName: "parking", booleanValue: ${filterSelection.parking.value} }`
              : ''
          },
          ${
            filterSelection.powerSupply.value !== undefined
              ? `{ propName: "powerSupply", booleanValue: ${filterSelection.powerSupply.value} }`
              : ''
          },
          ${
            filterSelection.elevator.value !== undefined
              ? `{ propName: "elevator", booleanValue: ${filterSelection.elevator.value} }`
              : ''
          },
          ${
            filterSelection.security.value !== undefined
              ? `{ propName: "security", booleanValue: ${filterSelection.security.value} }`
              : ''
          },
          ${
            filterSelection.type.length
              ? `{ propName: "type", stringValues: [${filterSelection.type.map((type: string) => `"${type}"`)}] }`
              : ''
          },
          ${filterSelection.status ? `{ propName: "readinessStatus", stringValues: "${filterSelection.status}" }` : ''},
          ${
            filterSelection.price.from && filterSelection.price.to
              ? `{ propName: "price", rangeValues: { min: ${filterSelection.price.from}, max: ${filterSelection.price.to} } }`
              : ''
          },
          ${
            filterSelection.numberOfRooms
              ? `{ propName: "numberOfRooms", stringValues: "${filterSelection.numberOfRooms}" }`
              : ''
          }
        ]
        ) {
          stockInfo {
            isPartialSale
            minAllowedArea
          }
          id
          name
          files
          type
          area
          numberOfBedrooms
          numberOfBathrooms
          price
          readinessStatus
          isPart
          partPercentage
          partArea
          partPrice
          userId
          address {
            city
            street
            house
          }
          coordinates {
            lat
            lon
          }
        }
      }`
    const favoriteObjectsQuery = `
      query favoriteObjects {
        favoriteObjects( sortCriteria: { propName: "${sortName}", order: ${sort}},
        criteria: [
          ${
            filterSelection.layout.value
              ? `{ propName: "layout", stringValues: "${filterSelection.layout.value}" }`
              : ''
          },
          ${
            filterSelection.area.from && filterSelection.area.to
              ? `{ propName: "area", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.from) / 10.76391041671).toFixed(0)
                    : filterSelection.area.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.area.to) / 10.76391041671).toFixed(0)
                    : filterSelection.area.to
                } } }`
              : ''
          },
          ${
            filterSelection.yearOfConstruction.from && filterSelection.yearOfConstruction.to
              ? `{ propName: "yearOfConstruction", rangeValues: { min: ${filterSelection.yearOfConstruction.from}, max: ${filterSelection.yearOfConstruction.to} } }`
              : ''
          },
          ${
            filterSelection.floor.from && filterSelection.floor.to
              ? `{ propName: "floor", rangeValues: { min: ${filterSelection.floor.from}, max: ${filterSelection.floor.to} } }`
              : ''
          },
          ${
            filterSelection.ceilingHeight.from && filterSelection.ceilingHeight.to
              ? `{ propName: "ceilingHeight", rangeValues: { min: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.from) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.from
                }, max: ${
                  context.currentUser?.preferences.measurementSystem === 'imperial'
                    ? (Number(filterSelection.ceilingHeight.to) / 3.28084).toFixed(0)
                    : filterSelection.ceilingHeight.to
                } } }`
              : ''
          },
          ${
            filterSelection.repair.value
              ? `{ propName: "repair", stringValues: "${filterSelection.repair.value}" }`
              : ''
          },
          ${filterSelection.view.value ? `{ propName: "view", stringValues: "${filterSelection.view.value}" }` : ''},
          ${
            filterSelection.parking.value !== undefined
              ? `{ propName: "parking", booleanValue: ${filterSelection.parking.value} }`
              : ''
          },
          ${
            filterSelection.powerSupply.value !== undefined
              ? `{ propName: "powerSupply", booleanValue: ${filterSelection.powerSupply.value} }`
              : ''
          },
          ${
            filterSelection.elevator.value !== undefined
              ? `{ propName: "elevator", booleanValue: ${filterSelection.elevator.value} }`
              : ''
          },
          ${
            filterSelection.security.value !== undefined
              ? `{ propName: "security", booleanValue: ${filterSelection.security.value} }`
              : ''
          },
          ${
            filterSelection.type.length
              ? `{ propName: "type", stringValues: [${filterSelection.type.map((type: string) => `"${type}"`)}] }`
              : ''
          },
          ${filterSelection.status ? `{ propName: "readinessStatus", stringValues: "${filterSelection.status}" }` : ''},
          ${
            filterSelection.price.from && filterSelection.price.to
              ? `{ propName: "price", rangeValues: { min: ${filterSelection.price.from}, max: ${filterSelection.price.to} } }`
              : ''
          },
          ${
            filterSelection.numberOfRooms
              ? `{ propName: "numberOfRooms", stringValues: "${filterSelection.numberOfRooms}" }`
              : ''
          }
        ]
        ) {
          stockInfo {
            isPartialSale
            minAllowedArea
          }
          id
          name
          files
          type
          area
          numberOfBedrooms
          numberOfBathrooms
          price
          readinessStatus
          isPart
          partPercentage
          partArea
          partPrice
          userId
          address {
            city
            street
            house
          }
          coordinates {
            lat
            lon
          }
        }
      }`
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: findMyObjects ? myObjectsQuery : findFavoriteObjects ? favoriteObjectsQuery : allObjectsQuery,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          !findMyObjects && !findFavoriteObjects && setTotal(result.data.data.objectSearch.total)
          findMyObjects
            ? setObjects(result.data.data.objects)
            : findFavoriteObjects
            ? setObjects(result.data.data.favoriteObjects)
            : setObjects(result.data.data.objectSearch.objects)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingTable(false))
  }
  const debouncedFilter = debounce(findFilteredObjects, 500)

  useEffect(() => {
    !filterOpen && debouncedFilter()
    return () => {
      debouncedFilter.cancel()
    }
    // eslint-disable-next-line
  }, [filterSelection])

  const addFavoriteHandler = (objectId: number) => {
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation AddFavorite {
            addFavorite(objectId: ${objectId}) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          result.data.data?.addFavorite.success
            ? setFavoriteIds((prev) => (prev.includes(objectId) ? prev : [...prev, objectId]))
            : alert(result.data.data?.addFavorite.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeFavoriteHandler = (objectId: number) => {
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation RemoveFavorite {
            removeFavorite(objectId: ${objectId}) {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          result.data.data?.removeFavorite.success
            ? setFavoriteIds((prev) => prev.filter((id) => id !== objectId))
            : alert(result.data.data?.removeFavorite.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="buy-object__objects">
      <div className="buy-object__form">
        {!findMyObjects && !findFavoriteObjects && <div className="buy-object__form-result">{total} results</div>}
        {findMyObjects && (
          <div className="objects__form-left">
            <h3 className="objects__form-title">My Objects</h3>
            <Link to="/objects" type="button" className="objects__form-buy button button--transparent">
              <span>Buy New</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 3.5V12.5M12.5 8H3.5"
                  stroke="#2D3748"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>

            <div className="objects__form-sizes">
              <button
                onClick={() => setShowShared(false)}
                type="button"
                className={showShared ? 'objects__form-size' : 'objects__form-size active'}
              >
                <span>FULL OBJECT</span>
              </button>

              <button
                onClick={() => setShowShared(true)}
                type="button"
                className={!showShared ? 'objects__form-size' : 'objects__form-size active'}
              >
                <span>PART OF OBJECT</span>
              </button>
            </div>
          </div>
        )}
        {findFavoriteObjects && (
          <div className="objects__form-left">
            <h3 className="objects__form-title">Favorite Objects</h3>
          </div>
        )}

        {!disableSearch && (
          <div className="buy-object__form-search">
            <input
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value)
                setPage(1)
              }}
              className="buy-object__form-search-input"
              type="search"
              placeholder="Type here..."
            />
            <img className="buy-object__form-search-icon" width="16" height="16" src={Search} alt="icon" />
          </div>
        )}

        <div className="objects__filters">
          <div className="objects__filter">
            <div className="objects__filter-btn">
              <img
                onClick={() => {
                  setSort(sort === 'asc' ? 'desc' : 'asc')
                  setPage(1)
                }}
                style={{ transform: sort === 'asc' ? '' : 'rotate(180deg)' }}
                className="objects__filter-icon"
                width="24"
                height="24"
                src={Sort}
                alt="sorting icon"
              />
              <button onClick={(e) => handleSortOpen(e)} className="objects__filter-text">
                Sorting
              </button>
              <Popover
                open={!!sortOpen}
                anchorEl={sortOpen}
                onClose={handleCloseSort}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ul className="filterPopover">
                  {sortList.map((item) => (
                    <li
                      key={item}
                      onClick={() => {
                        setSortName(item)
                        setSort('asc')
                        setPage(1)
                        handleCloseSort()
                      }}
                      className="filterPopover__item"
                      style={{
                        fontWeight: sortName === item ? '700' : '400',
                        color: sortName === item ? '#000000' : '#a0aec0',
                      }}
                    >
                      {item.replace(/([A-Z])/g, ' $1').trim()}
                    </li>
                  ))}
                </ul>
              </Popover>
            </div>
          </div>

          <div className="objects__filter">
            <button onClick={() => setFilterOpen(!filterOpen)} type="button" className="objects__filter-btn">
              <img className="objects__filter-icon" width="24" height="24" src={Filter} alt="filter icon" />
              <p className="objects__filter-text">Filter</p>
            </button>
          </div>

          <div className="objects__filter objects__filter-views">
            <button
              type="button"
              onClick={() => setViewType('block')}
              className={viewType === 'block' ? 'objects__filter-btn active' : 'objects__filter-btn'}
            >
              <img className="objects__filter-icon" width="24" height="24" src={ViewBlock} alt="view-block icon" />
            </button>

            <button
              type="button"
              onClick={() => setViewType('list')}
              className={viewType === 'list' ? 'objects__filter-btn active' : 'objects__filter-btn'}
            >
              <img className="objects__filter-icon" width="24" height="24" src={ViewList} alt="view-list icon" />
            </button>
          </div>
        </div>
      </div>

      {viewType === 'block' && (
        <ul className="buy-object__cards cards">
          {loadingTable && (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '10%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#df87f1',
                zIndex: 2,
              }}
            />
          )}
          {objects.map((item) => (
            <li style={{ opacity: loadingTable ? 0.5 : 1 }} key={item.id} className="card">
              <Link to={`/objects/${item.id}`} className="card__link">
                <div className="card__picture">
                  <div className="card__badge">{item.type}</div>
                  {!findMyObjects && <div className="card__badge-green">ROI 95%</div>}
                  <img
                    className="card__pic"
                    width="294"
                    height="300"
                    src={item.files.length ? `https://statepix.freeblock.site/uploads/objects/${item.files[0]}` : Card}
                    alt="card"
                  />
                </div>
                <div className="card__footer">
                  <div className="card__info">
                    <p className="card__location">
                      {item.address.city}, {item.address.house} {item.address.street}
                    </p>
                    <h4 className="card__title">{item.name}</h4>
                    {!item.stockInfo?.isPartialSale && <p className="card__text">{item.description}</p>}
                  </div>

                  <div className="card__advantages">
                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage1} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">
                          {measureSquareConvert(context, item.partArea || item.area)}
                          <sup>2</sup>
                        </p>
                      </div>
                    </div>

                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage2} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">{item.numberOfBedrooms}</p>
                      </div>
                    </div>

                    <div className="card__advantage">
                      <img className="card__advantage-icon" width="24" height="24" src={Advantage3} alt="icon" />

                      <div className="card__advantage-texts">
                        <p className="card__advantage-text">{item.numberOfBathrooms}</p>
                      </div>
                    </div>
                    {!item.stockInfo?.isPartialSale && (
                      <div className="card__advantage-sum">
                        {numeral(item.partPrice || item.price).format('0.0a')} USDT
                      </div>
                    )}
                  </div>
                  {item.stockInfo?.isPartialSale && (
                    <div className="card__percent">
                      <CircularProgress
                        size={56}
                        sx={{
                          color: '#df87f1',
                        }}
                        variant="determinate"
                        value={item.partPercentage || 100}
                      />
                      <span>{item.partPercentage?.toFixed(0) || 100}%</span>
                    </div>
                  )}

                  {item.stockInfo?.isPartialSale && (
                    <div className="card__price">
                      <span>{item.partPrice?.toLocaleString() || item.price.toLocaleString()} USDT</span>
                      <span>
                        {context.currentUser?.preferences.measurementSystem === 'imperial'
                          ? `${numeral(item.partArea || item.area * 10.76391041671).format('0.0a')} ft`
                          : `${numeral(item.partArea || item.area).format('0.0a')} m`}
                        <sup>2</sup>
                      </span>
                    </div>
                  )}
                </div>
              </Link>
              {item.userId === context.currentUser?.id ? (
                <div className="card__btns card__btns--column-2">
                  <Link to={`/objects/${item.id}`} className="card__btn button button--transparent btn">
                    SALE
                  </Link>
                  <Link to={`/objects/${item.id}`} className="card__btn button btn">
                    TRANSFER
                  </Link>
                </div>
              ) : (
                <div className="card__btns">
                  <Link to={`/objects/${item.id}`} className="card__btn button btn">
                    BUY NOW
                  </Link>
                </div>
              )}
              <button
                type="button"
                onClick={() =>
                  favoriteIds.includes(item.id) ? removeFavoriteHandler(item.id) : addFavoriteHandler(item.id)
                }
                className="card__favorite"
              >
                <img
                  className="card__favorite-pic"
                  width="30"
                  height="30"
                  src={favoriteIds.includes(item.id) ? FavoriteFill : Favorite}
                  alt="icon"
                />
              </button>
            </li>
          ))}

          {/* <li className="card">
        <div className="card__picture">
          <div className="card__badge">OFFICE SPACE</div>
          <div className="card__badge-green">ROI 95%</div>
          <img className="card__pic" width="294" height="300" src="./img/card.jpg" alt="card" />

          <button type="button" className="card__favorite">
            <img
              className="card__favorite-pic"
              width="30"
              height="30"
              src="./img/icons/favorite.svg"
              alt="icon"
            />
          </button>
        </div>

        <div className="card__footer">
          <div className="card__info">
            <p className="card__location">Dubai, 1 Sheikh Mohammed</p>
            <h4 className="card__title">Office in Burj Khalifa</h4>
          </div>

          <div className="card__advantages">
            <div className="card__advantage">
              <img
                className="card__advantage-icon"
                width="24"
                height="24"
                src="./img/icons/card-advantage-1.svg"
                alt="icon"
              />

              <div className="card__advantage-texts">
                <p className="card__advantage-text">
                  54m<sup>2</sup>
                </p>
              </div>
            </div>

            <div className="card__advantage">
              <img
                className="card__advantage-icon"
                width="24"
                height="24"
                src="./img/icons/card-advantage-2.svg"
                alt="icon"
              />

              <div className="card__advantage-texts">
                <p className="card__advantage-text">4</p>
              </div>
            </div>

            <div className="card__advantage">
              <img
                className="card__advantage-icon"
                width="24"
                height="24"
                src="./img/icons/card-advantage-3.svg"
                alt="icon"
              />

              <div className="card__advantage-texts">
                <p className="card__advantage-text">2</p>
              </div>
            </div>
          </div>

          <div className="card__percent">
            <span>75%</span>
          </div>
        </div>
      </li> */}
        </ul>
      )}

      {viewType === 'list' && (
        <div className="orders__table">
          <div className="table__container">
            {loadingTable && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: '10%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: '#df87f1',
                  zIndex: 2,
                }}
              />
            )}
            <table style={{ opacity: loadingTable ? 0.5 : 1 }} className="table">
              {/* <tr>
                <th style={{ paddingLeft: '80px' }}>Location</th>
                <th>Size</th>
                <th>Rooms</th>
                <th>Bathrooms</th>
                <th>Date and Time</th>
                <th>Type</th>
                <th>Condition</th>
                <th>Price</th>
                <th>Description</th>
              </tr> */}
              <tbody>
                {objects.map((item) => (
                  <tr onClick={() => navigate(`/objects/${item.id}`)} key={item.id}>
                    <td>
                      <div className="table__info">
                        <img
                          style={{ borderRadius: '10px' }}
                          width="60"
                          height="80"
                          src={
                            item.files.length
                              ? `https://statepix.freeblock.site/uploads/objects/${item.files[0]}`
                              : Card
                          }
                          alt="card"
                        />

                        <div className="table__info-texts">
                          <p className="table__info-address">
                            {item.address.city}, {item.address.house} {item.address.street}
                          </p>
                          <p className="table__info-name">{item.name}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="card__advantages">
                        <div className="card__advantage">
                          <img className="card__advantage-icon" width="24" height="24" src={Advantage1} alt="icon" />

                          <div className="card__advantage-texts">
                            <p className="card__advantage-text">
                              {measureSquareConvert(context, item.area)}
                              <sup>2</sup>
                            </p>
                          </div>
                        </div>

                        <div className="card__advantage">
                          <img className="card__advantage-icon" width="24" height="24" src={Advantage2} alt="icon" />

                          <div className="card__advantage-texts">
                            <p className="card__advantage-text">{item.numberOfBedrooms}</p>
                          </div>
                        </div>

                        <div className="card__advantage">
                          <img className="card__advantage-icon" width="24" height="24" src={Advantage3} alt="icon" />

                          <div className="card__advantage-texts">
                            <p className="card__advantage-text">{item.numberOfBathrooms}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{item.type}</td>
                    <td>{item.readinessStatus}</td>
                    <td>${item.price?.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {objects.length === 0 && (
        <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {findMyObjects
            ? "You don't have any objects yet"
            : findFavoriteObjects
            ? 'Add objects to favorites to see them here...'
            : 'No objects found...'}
        </div>
      )}
      {!findMyObjects && !findFavoriteObjects && (
        <div className="paginationContainer">
          <Select
            sx={{
              '@media screen and (max-width: 480px)': {
                display: 'none',
              },
            }}
            size="small"
            value={objectsPerPage}
            onChange={(e) => {
              setObjectsPerPage(e.target.value as number)
              setPage(1)
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={Math.ceil(total / objectsPerPage)}
            shape="rounded"
          />
        </div>
      )}
      <div className="modals__contents">
        <div className="modals__content">
          <div className={filterOpen ? 'modal show' : 'modal'} id="filters-modal">
            <div className="modal-content modal-content--large">
              <div className="modal__form">
                <div className="modal__head">
                  <h2 className="modal__title modal__title--left">Filters</h2>

                  <button onClick={() => setFilterOpen(!filterOpen)} className="close-modal-btn">
                    <img src={CloseModal} alt="close" />
                  </button>
                </div>

                <div className="modal__main">
                  <div className="filters-modal">
                    <ul className="filters-modal__list">
                      <li className="filters-modal__item">
                        <p className="filters-modal__text">
                          Area, {context.currentUser?.preferences.measurementSystem === 'imperial' ? 'ft2' : 'm2'}
                        </p>

                        <div className="filters-modal__rows">
                          <div className="filters-modal__row">
                            <p className="filters-modal__row-text">Total</p>
                            <input
                              onChange={(e) =>
                                setFilterSelection({
                                  ...filterSelection,
                                  area: { from: e.target.value, to: filterSelection.area.to },
                                })
                              }
                              className="filters-modal__input"
                              type="number"
                              value={filterSelection.area.from}
                            />
                            <p className="filters-modal__row-text">-</p>
                            <input
                              onChange={(e) =>
                                setFilterSelection({
                                  ...filterSelection,
                                  area: { from: filterSelection.area.from, to: e.target.value },
                                })
                              }
                              className="filters-modal__input"
                              type="number"
                              value={filterSelection.area.to}
                            />
                            <p className="filters-modal__row-text">
                              {context.currentUser?.preferences.measurementSystem === 'imperial' ? 'ft' : 'm'}
                              <sup>2</sup>
                            </p>
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Floor</p>

                        <div className="filters-modal__inputs filters-modal__inputs--five">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                floor: { from: '', to: '' },
                              })
                            }
                            className={`filters-modal__checkbox ${filterSelection.floor.from === '' ? 'checked' : ''}`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                floor: { from: '1', to: '5' },
                              })
                            }
                            className={`filters-modal__checkbox ${filterSelection.floor.from === '1' ? 'checked' : ''}`}
                          >
                            1-5
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                floor: { from: '5', to: '15' },
                              })
                            }
                            className={`filters-modal__checkbox ${filterSelection.floor.from === '5' ? 'checked' : ''}`}
                          >
                            5-15
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                floor: { from: '15', to: '30' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.floor.from === '15' ? 'checked' : ''
                            }`}
                          >
                            15-30
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                floor: { from: '30', to: '1000' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.floor.from === '30' ? 'checked' : ''
                            }`}
                          >
                            &gt;30
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Layout</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                layout: { value: '' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.layout.value === '' ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                layout: { value: 'adjacent' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.layout.value === 'adjacent' ? 'checked' : ''
                            }`}
                          >
                            Adjacent
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                layout: { value: 'isolated' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.layout.value === 'isolated' ? 'checked' : ''
                            }`}
                          >
                            Isolated
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Ceiling height</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                ceilingHeight: { from: '' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.ceilingHeight.from === '' ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                ceilingHeight: { from: '2.5', to: '100' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.ceilingHeight.from === '2.5' ? 'checked' : ''
                            }`}
                          >
                            From {measureConvert(context, 2.5, 1)}
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                ceilingHeight: { from: '3', to: '100' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.ceilingHeight.from === '3' ? 'checked' : ''
                            }`}
                          >
                            From {measureConvert(context, 3, 1)}
                          </div>
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                ceilingHeight: { from: '3.5', to: '100' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.ceilingHeight.from === '3.5' ? 'checked' : ''
                            }`}
                          >
                            From {measureConvert(context, 3.5, 1)}
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Repair</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                repair: { value: '' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.repair.value === '' ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                repair: { value: 'no_renovation' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.repair.value === 'no_renovation' ? 'checked' : ''
                            }`}
                          >
                            No renovation
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                repair: { value: 'cosmetic' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.repair.value === 'cosmetic' ? 'checked' : ''
                            }`}
                          >
                            Cosmetic
                          </div>
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                repair: { value: 'designer' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.repair.value === 'designer' ? 'checked' : ''
                            }`}
                          >
                            Designer
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">View</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                view: { value: '' },
                              })
                            }
                            className={`filters-modal__checkbox ${filterSelection.view.value === '' ? 'checked' : ''}`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                view: { value: 'yard' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.view.value === 'yard' ? 'checked' : ''
                            }`}
                          >
                            Outside the yard
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                view: { value: 'outside' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.view.value === 'outside' ? 'checked' : ''
                            }`}
                          >
                            Outside
                          </div>
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                view: { value: 'ocean' },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.view.value === 'ocean' ? 'checked' : ''
                            }`}
                          >
                            Outside the ocean
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Year of construction</p>

                        <div className="filters-modal__rows">
                          <div className="filters-modal__row">
                            <input
                              onChange={(e) =>
                                setFilterSelection({
                                  ...filterSelection,
                                  yearOfConstruction: {
                                    from: e.target.value,
                                    to: filterSelection.yearOfConstruction.to,
                                  },
                                })
                              }
                              className="filters-modal__input"
                              type="number"
                              value={filterSelection.yearOfConstruction.from}
                            />
                            <p className="filters-modal__row-text">-</p>
                            <input
                              onChange={(e) =>
                                setFilterSelection({
                                  ...filterSelection,
                                  yearOfConstruction: {
                                    from: filterSelection.yearOfConstruction.from,
                                    to: e.target.value,
                                  },
                                })
                              }
                              className="filters-modal__input"
                              type="number"
                              value={filterSelection.yearOfConstruction.to}
                            />
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Parking</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                parking: { value: undefined },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.parking.value === undefined ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                parking: { value: true },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.parking.value === true ? 'checked' : ''
                            }`}
                          >
                            Yes
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                parking: { value: false },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.parking.value === false ? 'checked' : ''
                            }`}
                          >
                            No
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Power supply</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                powerSupply: { value: undefined },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.powerSupply.value === undefined ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                powerSupply: { value: true },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.powerSupply.value === true ? 'checked' : ''
                            }`}
                          >
                            Yes
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                powerSupply: { value: false },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.powerSupply.value === false ? 'checked' : ''
                            }`}
                          >
                            No
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Elevators</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                elevator: { value: undefined },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.elevator.value === undefined ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                elevator: { value: true },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.elevator.value === true ? 'checked' : ''
                            }`}
                          >
                            Yes
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                elevator: { value: false },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.elevator.value === false ? 'checked' : ''
                            }`}
                          >
                            No
                          </div>
                        </div>
                      </li>

                      <li className="filters-modal__item">
                        <p className="filters-modal__text">Security</p>

                        <div className="filters-modal__inputs">
                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                security: { value: undefined },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.security.value === undefined ? 'checked' : ''
                            }`}
                          >
                            No matter
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                security: { value: true },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.security.value === true ? 'checked' : ''
                            }`}
                          >
                            Yes
                          </div>

                          <div
                            onClick={() =>
                              setFilterSelection({
                                ...filterSelection,
                                security: { value: false },
                              })
                            }
                            className={`filters-modal__checkbox ${
                              filterSelection.security.value === false ? 'checked' : ''
                            }`}
                          >
                            No
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="modal__footer modal__footer--end">
                  <button
                    onClick={() => setFilterSelection(filterObject)}
                    type="reset"
                    className="modal__btn btn-outline"
                  >
                    reset filters
                  </button>
                  <button onClick={findFilteredObjects} type="button" className="modal__btn btn">
                    SHOW OBJECTS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectsList
