import LockClosed from '../assets/img/icons/lock-closed.svg'
import SettingsCheck from '../assets/img/icons/settings-check.svg'
import Mail from '../assets/img/icons/mail.svg'
import Call from '../assets/img/icons/call.svg'
import Key from '../assets/img/icons/key.svg'
// import PhonePortrait from '../assets/img/icons/phone-portrait.svg'
// import Logout from '../assets/img/icons/log-out.svg'
import ShieldCheckmark from '../assets/img/icons/shield-checkmark.svg'
import Lock from '../assets/img/icons/registration-lock.svg'

import { useContext, useState } from 'react'
import { UserContext } from '../hooks/context'
import VerificationInput from 'react-verification-input'
import QRCode from 'react-qr-code'
import axios from 'axios'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import { CircularProgress, Snackbar } from '@mui/material'
import validator from 'validator'
import { unformat, useMask } from '@react-input/mask'

function Settings() {
  const { context, setContext } = useContext(UserContext)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>('')
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false)
  const [phoneChanged, setPhoneChanged] = useState<boolean>(false)
  const [gaStep, setGaStep] = useState<number>(1)
  const [authLink, setAuthLink] = useState<string>('')
  const [gaCode, setGaCode] = useState<string>('')
  const [manageMenu, setManageMenu] = useState<number>(1)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [repNewPassword, setRepNewPassword] = useState<string>('')
  const [newPhone, setNewPhone] = useState<string>('')
  const inputPhoneRef = useMask({ mask: '+_ (___) ___-__-__', replacement: { _: /\d/ } })

  const emailMasker = (email: string) => {
    if (!email) return ''
    const [username, domain] = email.split('@')
    const maskedUsername = `${username.slice(0, Math.floor(username.length / 2))}***`
    return `${maskedUsername}@${domain}`
  }
  const phoneMasker = (phoneNumber: string) => {
    if (!phoneNumber) return ''
    const visibleDigits = 3 // Number of digits to keep visible at the beginning and end
    const countryCodeLength = phoneNumber.length - visibleDigits * 2
    const countryCode = phoneNumber.slice(0, countryCodeLength)
    const maskedPart = '*'.repeat(visibleDigits)
    const visiblePart = phoneNumber.slice(-visibleDigits)
    return `${countryCode}${maskedPart}${visiblePart}`
  }

  const generateAuthenticator = () => {
    setFormLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation GenerateMFASecret {
            generateMFASecret {
              url
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else {
          setAuthLink(result.data.data?.generateMFASecret.url)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setFormLoading(false))
  }
  const enableAuthenticator = () => {
    setFormLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation EnableMFA {
            enableMFA(code: "${gaCode}") {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else if (result.data.data?.enableMFA.success) {
          setContext((prev: any) => ({ ...prev, currentUser: { ...prev.currentUser, mfaEnabled: true } }))
        } else {
          setAlertText(result.data.data?.enableMFA.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setFormLoading(false))
  }
  const disableAuthenticator = () => {
    setFormLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation DisableMFA {
            disableMFA {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else if (result.data.data?.disableMFA.success) {
          setContext((prev: any) => ({ ...prev, currentUser: { ...prev.currentUser, mfaEnabled: false } }))
          setGaStep(1)
        } else {
          setAlertText(result.data.data?.disableMFA.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setFormLoading(false))
  }

  const changePasswordHandler = () => {
    setFormLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation ChangePassword {
            changePassword(oldPassword: "${oldPassword}", newPassword: "${newPassword}") {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            setAlertText(result.data.errors?.[0].message)
            setAlertOpen(true)
          }
        } else if (result.data.data?.changePassword.success) {
          setPasswordChanged(true)
        } else {
          setAlertText(result.data.data?.changePassword.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setFormLoading(false))
  }

  const changePhoneHandler = () => {
    setFormLoading(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          mutation UpdatePhoneNumber {
            updatePhoneNumber(phoneNumber: "+${unformat(newPhone, {
              mask: '+_ (___) ___-__-__',
              replacement: { _: /\d/ },
            })}") {
              success
              message
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else if (result.data.data?.updatePhoneNumber.success) {
          setPhoneChanged(true)
          setContext((prev: any) => ({ ...prev, currentUser: { ...prev.currentUser, phoneNumber: newPhone } }))
        } else {
          setAlertText(result.data.data?.updatePhoneNumber.message)
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setFormLoading(false))
  }

  return (
    <section className="settings">
      <div className="settings__inner">
        <div className="settings__security">
          <h2 className="settings__title">Security</h2>

          <div className="settings__rows">
            <div className="settings__row">
              <div className="settings__info">
                <img className="settings__info-icon" width="16" height="16" src={LockClosed} alt="lock-closed icon" />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">Authenticator App</h3>
                  <p className="settings__info-text">
                    Use Google Authenticator to protect your account and transactions.
                  </p>
                </div>
              </div>

              <div className="settings__actions">
                {context.currentUser?.mfaEnabled && (
                  <div className="settings__action">
                    <img
                      className="settings__action-icon"
                      width="16"
                      height="16"
                      src={SettingsCheck}
                      alt="cjeck icon"
                    />

                    <span>On</span>
                  </div>
                )}
                <button
                  onClick={() => setManageMenu(1)}
                  type="button"
                  className="settings__action-btn"
                  style={manageMenu === 1 ? { borderColor: '#e488cc', color: '#e488cc' } : {}}
                >
                  MANAGE
                </button>
              </div>
            </div>

            <div className="settings__row">
              <div className="settings__info">
                <img className="settings__info-icon" width="16" height="16" src={Mail} alt="mail icon" />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">Email</h3>
                  <p className="settings__info-text">Use your email to protect your account and transactions.</p>
                </div>
              </div>

              <div className="settings__actions">
                <div className="settings__action">
                  <span>{emailMasker(context.currentUser?.email)}</span>
                </div>
                <button
                  onClick={() => setManageMenu(2)}
                  type="button"
                  className="settings__action-btn"
                  style={manageMenu === 2 ? { borderColor: '#e488cc', color: '#e488cc' } : {}}
                >
                  MANAGE
                </button>
              </div>
            </div>

            <div className="settings__row">
              <div className="settings__info">
                <img className="settings__info-icon" width="16" height="16" src={Call} alt="call icon" />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">Phone Number</h3>
                  <p className="settings__info-text">Use your phone number to protect your account and transactions.</p>
                </div>
              </div>

              <div className="settings__actions">
                {context.currentUser?.phoneNumber && (
                  <div className="settings__action">
                    <img
                      className="settings__action-icon"
                      width="16"
                      height="16"
                      src={SettingsCheck}
                      alt="cjeck icon"
                    />
                    <span>{phoneMasker(context.currentUser?.phoneNumber)}</span>
                  </div>
                )}
                <button
                  onClick={() => setManageMenu(3)}
                  type="button"
                  className="settings__action-btn"
                  style={manageMenu === 3 ? { borderColor: '#e488cc', color: '#e488cc' } : {}}
                >
                  MANAGE
                </button>
              </div>
            </div>

            <div className="settings__row">
              <div className="settings__info">
                <img className="settings__info-icon" width="16" height="16" src={Key} alt="key icon" />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">Login Password</h3>
                  <p className="settings__info-text">Login password is used to log in to your account.</p>
                </div>
              </div>

              <div className="settings__actions">
                <button
                  onClick={() => setManageMenu(4)}
                  type="button"
                  className="settings__action-btn"
                  style={manageMenu === 4 ? { borderColor: '#e488cc', color: '#e488cc' } : {}}
                >
                  MANAGE
                </button>
              </div>
            </div>

            {/* <div className="settings__row">
              <div className="settings__info">
                <img
                  className="settings__info-icon"
                  width="16"
                  height="16"
                  src={PhonePortrait}
                  alt="phone-portrait icon"
                />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">My Devices</h3>
                  <p className="settings__info-text">
                    Manage devices that have login status, and view your device history.
                  </p>
                </div>
              </div>

              <div className="settings__actions">
                <button
                  onClick={() => setManageMenu(5)}
                  type="button"
                  className="settings__action-btn"
                  style={manageMenu === 5 ? { borderColor: '#e488cc', color: '#e488cc' } : {}}
                >
                  MANAGE
                </button>
              </div>
            </div>

            <div className="settings__row">
              <div className="settings__info">
                <img className="settings__info-icon" width="16" height="16" src={Logout} alt="log-out icon" />

                <div className="settings__info-texts">
                  <h3 className="settings__info-label">Account Activity</h3>
                  <p className="settings__info-text">
                    Last login: 2024-05-27 09:18:26
                    <br />
                    Suspicious account activity?
                  </p>
                </div>
              </div>

              <div className="settings__actions">
                <button type="button" className="settings__action-btn">
                  Disable Account
                </button>
              </div>
            </div> */}
          </div>
        </div>
        {manageMenu === 1 && (
          <>
            {!context.currentUser?.mfaEnabled ? (
              <div className="settings__steps">
                {formLoading && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '15px',
                      backgroundColor: '#ffffffb3',
                      zIndex: 2,
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: '#e488cc',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                )}
                <h3 className="settings__steps-title">
                  Your wallet is already ready for use, all that remains is to <span>configure F2A</span> to reliably
                  protect your funds
                </h3>

                <div className="settings__steps-steps">
                  <div
                    onClick={() => setGaStep(gaStep > 1 ? 1 : gaStep)}
                    className={`settings__steps-step ${gaStep >= 1 && 'active'}`}
                  >
                    <div className="settings__steps-border"></div>
                    <p className="settings__steps-text">Install</p>
                  </div>

                  <div
                    onClick={() => setGaStep(gaStep > 2 ? 2 : gaStep)}
                    className={`settings__steps-step ${gaStep >= 2 && 'active'}`}
                  >
                    <div className="settings__steps-border"></div>
                    <p className="settings__steps-text">Setting</p>
                  </div>

                  <div
                    onClick={() => setGaStep(gaStep > 3 ? 3 : gaStep)}
                    className={`settings__steps-step ${gaStep >= 3 && 'active'}`}
                  >
                    <div className="settings__steps-border"></div>
                    <p className="settings__steps-text">Scan</p>
                  </div>

                  <div
                    onClick={() => setGaStep(gaStep > 4 ? 4 : gaStep)}
                    className={`settings__steps-step ${gaStep >= 4 && 'active'}`}
                  >
                    <div className="settings__steps-border"></div>
                    <p className="settings__steps-text">Enter Code</p>
                  </div>
                </div>

                {gaStep === 1 && (
                  <div className="settings__steps-label">Install Google Authenticator on your device</div>
                )}
                {gaStep === 2 && <div className="settings__steps-label">Set up the device as described: </div>}
                {gaStep === 3 && (
                  <div className="settings__steps-label">
                    Scan QR with Google Autentificator <br /> and press "Next"{' '}
                  </div>
                )}
                {gaStep === 4 && (
                  <div className="settings__steps-label">
                    Enter the code from <br /> Google Authenticator
                  </div>
                )}

                {gaStep === 1 && (
                  <div className="settings__steps-qodes">
                    <div className="settings__steps-qode">
                      <QRCode
                        size={148}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        viewBox={`0 0 256 256`}
                      />
                      <p>iOS</p>
                    </div>

                    <div className="settings__steps-qode">
                      <QRCode
                        size={148}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        viewBox={`0 0 256 256`}
                      />
                      <p>Android</p>
                    </div>
                  </div>
                )}

                {gaStep === 2 && (
                  <ul className="settings__steps-points">
                    <li className="settings__steps-point">Open Google Authenticator </li>
                    <li className="settings__steps-point">Click "+"</li>
                    <li className="settings__steps-point">Select scan QR code</li>
                    <li className="settings__steps-point">Click "Next" on this page</li>
                  </ul>
                )}

                {gaStep === 3 && (
                  <div className="settings__steps-qodes">
                    <div className="settings__steps-qode">
                      {authLink ? (
                        <QRCode
                          size={148}
                          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                          value={authLink}
                          viewBox={`0 0 256 256`}
                        />
                      ) : (
                        <CircularProgress sx={{ color: '#df87f1' }} />
                      )}
                    </div>
                  </div>
                )}

                {gaStep === 4 && (
                  <VerificationInput
                    placeholder=""
                    value={gaCode}
                    onChange={(value) => setGaCode(value)}
                    length={6}
                    validChars="0-9"
                    inputProps={{ inputMode: 'numeric', autoComplete: 'one-time-code' }}
                    classNames={{
                      container: 'settings__inputs-list',
                      character: 'settings__inputs-input',
                      characterInactive: 'settings__inputs-input--inactive',
                      characterSelected: 'settings__inputs-input--selected',
                      characterFilled: 'settings__inputs-input--filled',
                    }}
                  />
                )}

                <button
                  onClick={() => {
                    if (gaStep === 2) {
                      generateAuthenticator()
                      setGaStep(gaStep + 1)
                    } else if (gaStep === 4) {
                      enableAuthenticator()
                    } else {
                      setGaStep(gaStep + 1)
                    }
                  }}
                  type="button"
                  className="settings__steps-btn btn"
                >
                  {gaStep === 4 ? 'CONFIRM' : 'NEXT STEP'}
                </button>
              </div>
            ) : (
              <div className="settings__steps">
                <h3 className="settings__steps-title">Google Authentificator was successfully enabled</h3>
                <img style={{ width: '148px', margin: 'auto auto' }} src={ShieldCheckmark} alt="check" />
                <div className="settings__actions">
                  <button
                    onClick={() => disableAuthenticator()}
                    type="button"
                    style={{ width: '100%' }}
                    className="settings__action-btn"
                  >
                    Disable Google Authenticator
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {manageMenu === 3 && (
          <div className="settings__changes">
            {formLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '15px',
                  backgroundColor: '#ffffffb3',
                  zIndex: 2,
                }}
              >
                <CircularProgress
                  sx={{
                    color: '#e488cc',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            )}
            {!phoneChanged && (
              <div className="settings__changes-inner">
                <h3 className="settings__changes-title">Phone</h3>

                <div className="settings__changes-rows">
                  <div className="settings__changes-row">
                    <label className="settings__changes-label">NEW Phone</label>
                    <div className="settings__changes-input-wrapper">
                      <input
                        placeholder="+_ (___) ___-__-__"
                        ref={inputPhoneRef}
                        className="settings__changes-input registration__input"
                        type="tel"
                        value={newPhone}
                        onChange={(e) => {
                          setNewPhone(e.target.value)
                        }}
                      />
                      <img className="settings__changes-icon" width="20" height="20" src={Lock} alt="icon" />
                    </div>
                  </div>
                </div>

                <button
                  disabled={
                    !validator.isMobilePhone(
                      unformat(newPhone, { mask: '+_ (___) ___-__-__', replacement: { _: /\d/ } })
                    )
                  }
                  type="button"
                  className={`settings__steps-btn btn button ${
                    !validator.isMobilePhone(
                      unformat(newPhone, { mask: '+_ (___) ___-__-__', replacement: { _: /\d/ } })
                    )
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={changePhoneHandler}
                >
                  CONFIRM
                </button>
              </div>
            )}
            {phoneChanged && (
              <div className="settings__changes-inner">
                <h3 className="settings__steps-title">Your new phone number was successfully changed</h3>
                <img style={{ width: '148px', margin: 'auto auto' }} src={ShieldCheckmark} alt="check" />
              </div>
            )}
          </div>
        )}
        {manageMenu === 4 && (
          <div className="settings__changes">
            {formLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '15px',
                  backgroundColor: '#ffffffb3',
                  zIndex: 2,
                }}
              >
                <CircularProgress
                  sx={{
                    color: '#e488cc',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            )}
            {!passwordChanged && (
              <div className="settings__changes-inner">
                <h3 className="settings__changes-title">Password</h3>

                <div className="settings__changes-rows">
                  <div className="settings__changes-row">
                    <label className="settings__changes-label">OLD PASSWORD</label>
                    <div className="settings__changes-input-wrapper">
                      <input
                        className="settings__changes-input registration__input"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value.trim())}
                        placeholder="********"
                      />
                      <img className="settings__changes-icon" width="20" height="20" src={Lock} alt="icon" />
                    </div>
                  </div>

                  <div className="settings__changes-row">
                    <label className="settings__changes-label">NEW PASSWORD</label>
                    <div className="settings__changes-input-wrapper">
                      <input
                        className="settings__changes-input registration__input"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value.trim())}
                        placeholder="********"
                      />
                      <img className="settings__changes-icon" width="20" height="20" src={Lock} alt="icon" />
                    </div>
                  </div>

                  <div className="settings__changes-row">
                    <label className="settings__changes-label">REPEAT NEW PASSWORD</label>
                    <div className="settings__changes-input-wrapper">
                      <input
                        className="settings__changes-input registration__input"
                        type="password"
                        value={repNewPassword}
                        onChange={(e) => setRepNewPassword(e.target.value.trim())}
                        placeholder="********"
                      />
                      <img className="settings__changes-icon" width="20" height="20" src={Lock} alt="icon" />
                    </div>
                  </div>
                </div>

                <button
                  disabled={!oldPassword || !validator.isStrongPassword(newPassword) || newPassword !== repNewPassword}
                  type="button"
                  className={`settings__steps-btn btn button ${
                    !oldPassword || !validator.isStrongPassword(newPassword) || newPassword !== repNewPassword
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={changePasswordHandler}
                >
                  CONFIRM
                </button>
              </div>
            )}
            {passwordChanged && (
              <div className="settings__changes-inner">
                <h3 className="settings__steps-title">Your new password was successfully changed</h3>
                <img style={{ width: '148px', margin: 'auto auto' }} src={ShieldCheckmark} alt="check" />
              </div>
            )}
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertText}
        autoHideDuration={3000}
        ContentProps={{
          sx: {
            backgroundColor: '#e488cc',
            color: '#fff',
          },
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            borderRadius: '10px',
            marginTop: '75px',
          },
        }}
      />
    </section>
  )
}

export default Settings
