import Logo from '../assets/img/logo.svg'
import Mail from '../assets/img/icons/registration-mail.svg'
import Lock from '../assets/img/icons/registration-lock.svg'

import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { UserContext } from '../hooks/context'
import axios from 'axios'
import { CircularProgress } from '@mui/material'
import validator from 'validator'

function Login() {
  const [remember, setRemember] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const [loading, setLoading] = useState(false)
  const { setContext } = useContext(UserContext)
  const navigate = useNavigate()

  const loginHandler = () => {
    setLoading(true)
    axios
      .post(
        'https://api.statepix.io/api/v1/auth/signin',
        {
          email,
          password,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setContext({ accessToken: response.data.accessToken })
        localStorage.setItem('StatePix_accessToken', response.data.accessToken)
        remember && localStorage.setItem('StatePix_refreshToken', response.data.refreshToken)
        setLoading(false)
      })
      .catch((error) => {
        if (error.response?.data.message === 'MFA_CODE_REQUIRED') {
          navigate(`/mfacode`, { state: { email, password, remember } })
        }
        setLoading(false)
        setErrorText(error.response?.data.message)
      })
  }

  return (
    <div className="wrapper wrapper--center wrapper--gradient">
      <div className="registration">
        <div className="registration__inner">
          <Link className="registration__logo" to="/home">
            <img className="registration__logo-img" width="131" height="28" src={Logo} alt="logo img" />
          </Link>

          <div className="registration__head">
            <h1 className="registration__title">Log In</h1>
            <p className="registration__text">
              A ipsum sagittis ultrices tristique dictum. Pharetra orci ac vivamus eleifend massa adipiscing.
            </p>
          </div>

          <div className="registration__main">
            <div className="registration__rows">
              <div className="registration__row">
                <label className="registration__label">email</label>

                <div className="registration__input-container">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value.trim())
                      setErrorText('')
                    }}
                    value={email}
                    className="registration__input"
                    type="email"
                    placeholder="john_johnson@gmail.com"
                  />
                  <img className="registration__input-icon" width="20" height="20" src={Mail} alt="icon" />
                </div>
              </div>

              <div className="registration__row">
                <label className="registration__label">PASSWORD</label>

                <div className="registration__input-container">
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value.trim())
                      setErrorText('')
                    }}
                    value={password}
                    className="registration__input"
                    type="password"
                    placeholder="********"
                  />
                  <img className="registration__input-icon" width="20" height="20" src={Lock} alt="icon" />
                </div>
              </div>

              <div className="registration__row">
                <div className="registration__policy">
                  <input
                    onChange={() => setRemember(!remember)}
                    checked={remember}
                    className="registration__policy-checkbox"
                    type="checkbox"
                    id="policy-checkbox"
                  />
                  <label className="registration__policy-label" htmlFor="policy-checkbox">
                    <div className="registration__policy-check"></div>
                    <p className="registration__policy-text">Remember me </p>
                  </label>

                  <Link className="registration__link" to={'/recovery'}>
                    Forgot Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>{errorText.split('_').join(' ')}</div>
          <div className="registration__footer">
            {loading ? (
              <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '0 auto' }} />
            ) : (
              <button
                disabled={!validator.isEmail(email) || !password}
                className={`registration__btn btn ${!validator.isEmail(email) || !password ? 'disabled' : ''}`}
                onClick={loginHandler}
              >
                LOG IN
              </button>
            )}
            <div className="registration__footer-row">
              <p className="registration__footer-text">Don't have an account? </p>
              <Link className="registration__footer-link" to="/register">
                Register now!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
