import { Link } from 'react-router-dom'
import Aside1 from '../assets/img/icons/aside-1.svg'
import Aside2 from '../assets/img/icons/aside-2.svg'
import Aside3 from '../assets/img/icons/aside-3.svg'
import Aside4 from '../assets/img/icons/aside-4.svg'
import Aside5 from '../assets/img/icons/aside-5.svg'
import Aside6 from '../assets/img/icons/aside-6.svg'
import AsideHelp from '../assets/img/icons/aside-help.svg'

function Aside({ pathname }: { pathname: string }) {
  return (
    <aside className="aside">
      <div className="aside__inner">
        <ul className="aside__list">
          <li className={`aside__item ${pathname.split('/')[1] === 'my-objects' ? 'active' : ''}`}>
            <Link to="/my-objects" className="aside__linkn">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside1} alt="icon" />
              </div>

              <p className="aside__link-text">Objects</p>
            </Link>
          </li>

          <li className={`aside__item ${pathname.split('/')[1] === 'wallet' ? 'active' : ''}`}>
            <Link className="aside__linkn" to="/wallet">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside2} alt="icon" />
              </div>

              <p className="aside__link-text">Wallet</p>
            </Link>
          </li>

          <li className={`aside__item ${pathname.split('/')[1] === 'orders' ? 'active' : ''}`}>
            <Link className="aside__linkn" to="/orders">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside3} alt="icon" />
              </div>

              <p className="aside__link-text">Orders</p>
            </Link>
          </li>

          <li className={`aside__item ${pathname.split('/')[1] === 'history' ? 'active' : ''}`}>
            <Link className="aside__linkn" to="/history">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside4} alt="icon" />
              </div>

              <p className="aside__link-text">History</p>
            </Link>
          </li>

          <li className={`aside__item ${pathname.split('/')[1] === 'favorite' ? 'active' : ''}`}>
            <Link className="aside__linkn" to="/favorite">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside5} alt="icon" />
              </div>

              <p className="aside__link-text">Favorite</p>
            </Link>
          </li>

          <li className={`aside__item ${pathname.split('/')[1] === 'settings' ? 'active' : ''}`}>
            <Link className="aside__linkn" to="/settings">
              <div className="aside__link-icon">
                <img width="15" height="15" src={Aside6} alt="icon" />
              </div>

              <p className="aside__link-text">Settings</p>
            </Link>
          </li>
        </ul>

        <div className="aside__help">
          <div className="aside__help-icon">
            <img width="24" height="24" src={AsideHelp} alt="icon" />
          </div>

          <h4 className="aside__help-label">Need help?</h4>
          <p className="aside__help-text">Please check our docs</p>

          <a className="aside__help-btn" href="https://www.google.com/">
            DOCUMENTATION
          </a>
        </div>
      </div>
    </aside>
  )
}

export default Aside
