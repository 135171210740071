import Calendar from '../assets/img/icons/datepicker.svg'
import Avatar from '../assets/img/icons/avatar-solid.svg'
import ShieldCheckmark from '../assets/img/icons/shield-checkmark.svg'
import Card from '../assets/img/table-card.png'
import Advantage1 from '../assets/img/icons/card-advantage-1.svg'
import Advantage2 from '../assets/img/icons/card-advantage-2.svg'
import Advantage3 from '../assets/img/icons/card-advantage-3.svg'
import Tether from '../assets/img/tether.svg'
import ETH from '../assets/img/ethereum.svg'
import BTC from '../assets/img/bitcoin.svg'
import Matic from '../assets/img/matic.svg'
import Doge from '../assets/img/doge.svg'
import 'react-datepicker/dist/react-datepicker.css'

import { useContext, useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { CircularProgress, MenuItem, Pagination, Select, TextField } from '@mui/material'

import DatePicker from 'react-datepicker'
import axios from 'axios'
import { UserContext } from '../hooks/context'
import handleTokenRefresh from '../hooks/handleTokenRefresh'
import measureSquareConvert from '../hooks/measureSquareConvert'
import moment from 'moment'
import { Link } from 'react-router-dom'
// import RelatedObjects from '../components/RelatedObjects'

function P2P() {
  const { context, setContext } = useContext(UserContext)
  const [objectType, setObjectType] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<string>('')
  const [loadingTable, setLoadingTable] = useState<boolean>(true)
  const [objectsPerPage, setObjectsPerPage] = useState<number>(10)
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [startDate, endDate] = dateRange
  const [currencyChoice, setCurrencyChoice] = useState<string>('USDT')
  const [datepickerRangeError, setDatepickerRangeError] = useState<boolean>(false)

  const [objects, setObjects] = useState<any[]>([])

  const currencyList = {
    crypto: ['USDT', 'ETH', 'BTC', 'MATIC', 'DOGE'],
  }
  const currencyIconHandler = (item: string) => {
    switch (item) {
      case 'USDT':
        return Tether
      case 'ETH':
        return ETH
      case 'BTC':
        return BTC
      case 'MATIC':
        return Matic
      case 'DOGE':
        return Doge
      default:
        return Tether
    }
  }

  useEffect(() => {
    setLoadingTable(true)
    axios({
      url: 'https://api.statepix.io/graphql',
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + context.accessToken,
      },
      method: 'post',
      data: {
        query: `
          query P2pObjectSearch {
            p2pObjectSearch(page: ${page - 1}, recordsPerPage: ${objectsPerPage}, ${
          objectType && `criteria: {propName: "isPartialSale", booleanValue: ${objectType === 'part_object'}}`
        }) {
              objects {
                stockInfo {
                  isPartialSale
                  minAllowedArea
                  createdAt
                }
                id
                name
                files
                type
                readinessStatus
                isPart
                area
                partArea
                partPercentage
                numberOfBedrooms
                numberOfBathrooms
                price
                partPrice
                readinessStatus
                userId
                address {
                  city
                  street
                  house
                }
                coordinates {
                  lat
                  lon
                }
              }
              total
            }
          }
          `,
      },
    })
      .then((result) => {
        if (result.data.data === null) {
          if (result.data.errors?.[0].extensions.code === 'UNAUTHENTICATED') {
            handleTokenRefresh(setContext)
          } else {
            alert(result.data.errors?.[0].message)
          }
        } else {
          setObjects(result.data.data.p2pObjectSearch.objects)
          setTotal(result.data.data.p2pObjectSearch.total)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingTable(false))
  }, [context.accessToken, setContext, objectType, page, objectsPerPage])

  return (
    <div className="fullscreen fullscreen--p2p">
      <div className="content">
        <div className="breadcrumbs">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <p className="breadcrumbs__link">Products</p>
            </li>
            <li className="breadcrumbs__item">P2P</li>
          </ul>
        </div>
        <h1 className="page-title">P2P</h1>
        <section className="p2p">
          <div className="p2p__inner">
            <div className="p2p__form">
              <TextField
                value={inputAmount}
                onChange={(e) => setInputAmount(e.target.value)}
                variant="outlined"
                type="number"
                size="small"
                placeholder="Enter amount"
                style={{ maxWidth: '180px' }}
              />
              <Select
                size="small"
                value={currencyChoice}
                onChange={(e) => {
                  setCurrencyChoice(e.target.value)
                }}
                style={{ minWidth: '150px' }}
                displayEmpty
              >
                {currencyList.crypto.map((item) => (
                  <MenuItem key={item} value={item}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <img
                        className="token-select__icon"
                        width="20"
                        height="20"
                        src={currencyIconHandler(item)}
                        alt="token"
                      />
                      <p className="token-select__text">{item}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>

              <Select
                size="small"
                value={objectType}
                onChange={(e) => {
                  setObjectType(e.target.value)
                }}
                style={{ minWidth: '150px', fontSize: '12px', minHeight: '40px', color: '#a0aec0' }}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>

                <MenuItem value="full_object">Full object</MenuItem>
                <MenuItem value="part_object">Part object</MenuItem>
              </Select>
              <label className="calendar__wrapper">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Date"
                  onCalendarOpen={() => setDatepickerRangeError(false)}
                  onBlur={() => (!startDate || !endDate) && setDatepickerRangeError(true)}
                  onChange={(update: any) => {
                    setDateRange(update)
                  }}
                  showIcon
                  icon={<img src={Calendar} alt="calendar" />}
                />
              </label>
              <p style={{ color: '#e488cc' }}>{datepickerRangeError && 'Please select full date range'}</p>
              <Link to="/my-objects" type="button" className="p2p__btn objects__form-buy">
                Add New
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 3.5V12.5M12.5 8H3.5"
                    stroke="#2D3748"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Link>
            </div>

            <div className="p2p__main">
              <div className="p2p__table">
                <div className="table__container">
                  {!loadingTable ? (
                    objects.length ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>maker</th>
                            <th>object</th>
                            <th>Information</th>
                            <th>status</th>
                            <th>part</th>
                            <th>price, USDT</th>
                            <th>ROI</th>
                            <th>date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {objects.map((item) => (
                            <tr key={item.id}>
                              <td>
                                <div className="table__info">
                                  <div className="table__info-avatar">
                                    <img width="28" height="28" src={Avatar} alt="avatar" />
                                  </div>

                                  <div className="table__info-texts">
                                    <div className="table__info-nickname">
                                      <h4>alhimik000</h4>

                                      <img src={ShieldCheckmark} alt="shield icon" />
                                    </div>

                                    <div className="table__info-orders">
                                      <div className="table__info-order">371 orders</div>
                                      <div className="table__info-order">96.20% completed</div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="table__info">
                                  <img
                                    width="60"
                                    height="60"
                                    src={
                                      item.files.length
                                        ? `https://statepix.freeblock.site/uploads/objects/${item.files[0]}`
                                        : Card
                                    }
                                    alt="card"
                                  />

                                  <div className="table__info-texts">
                                    <p className="table__info-address">
                                      {item.address.city}, {item.address.house} {item.address.street}
                                    </p>
                                    <p className="table__info-name">{item.name}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="card__advantages">
                                  <div className="card__advantage">
                                    <img
                                      className="card__advantage-icon"
                                      width="24"
                                      height="24"
                                      src={Advantage1}
                                      alt="icon"
                                    />

                                    <div className="card__advantage-texts">
                                      <p className="card__advantage-text">
                                        {measureSquareConvert(context, item.area)}
                                        <sup>2</sup>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="card__advantage">
                                    <img
                                      className="card__advantage-icon"
                                      width="24"
                                      height="24"
                                      src={Advantage2}
                                      alt="icon"
                                    />

                                    <div className="card__advantage-texts">
                                      <p className="card__advantage-text">{item.numberOfBedrooms}</p>
                                    </div>
                                  </div>

                                  <div className="card__advantage">
                                    <img
                                      className="card__advantage-icon"
                                      width="24"
                                      height="24"
                                      src={Advantage3}
                                      alt="icon"
                                    />

                                    <div className="card__advantage-texts">
                                      <p className="card__advantage-text">{item.numberOfBathrooms}</p>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div
                                  className={`table__status-text table__status-text${
                                    item.readinessStatus === 'ready' ? '--complete' : '--progress'
                                  }`}
                                >
                                  {item.readinessStatus}
                                </div>
                              </td>

                              <td>
                                <div className="table__diagram">
                                  <div
                                    className="table__diagram-rogressbar object__percent-progressbar"
                                    role="progressbar"
                                    style={{
                                      background: `radial-gradient(closest-side, #fff 50%, transparent 0 99.9%, #fff 0), conic-gradient(#e488cc calc(${
                                        item.partPercentage ?? 100
                                      } * 1%), #e6ebf2 0)`,
                                      opacity: item.stockInfo.isPartialSale ? 1 : 0,
                                    }}
                                  ></div>

                                  <div className="table__diagram-text">
                                    {measureSquareConvert(context, item.partArea ?? item.area)}
                                    <sup>2</sup>{' '}
                                    {item.stockInfo.isPartialSale && `(${(item.partPercentage ?? 100).toFixed(2)}%)`}
                                  </div>
                                </div>
                              </td>

                              <td>{(item.partPrice ?? item.price).toLocaleString()} USDT</td>

                              <td>
                                <div className="table__gradient-text">120%</div>
                              </td>

                              <td>
                                <div className="table__dates">
                                  <p className="table__date">
                                    {moment(Number(item.stockInfo.createdAt)).format('DD.MM.YY, hh:mm')}
                                  </p>
                                </div>
                              </td>

                              <td>
                                <div className="table__actions">
                                  {context.currentUser?.id === item.userId ? (
                                    <div className="btn button--transparent">On sale</div>
                                  ) : (
                                    <Link to={`/objects/${item.id}`} type="button" className="table__btn btn button">
                                      Buy Now
                                    </Link>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="table__no-objects-text">There are no objects for sale...</div>
                    )
                  ) : (
                    <CircularProgress sx={{ color: '#df87f1' }} style={{ display: 'block', margin: '100px auto' }} />
                  )}
                </div>
              </div>
              <div className="paginationContainer">
                <Select
                  sx={{
                    '@media screen and (max-width: 480px)': {
                      display: 'none',
                    },
                  }}
                  size="small"
                  value={objectsPerPage}
                  onChange={(e) => {
                    setObjectsPerPage(e.target.value as number)
                    setPage(1)
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
                <Pagination
                  onChange={(e, page) => setPage(page)}
                  page={page}
                  count={Math.ceil(total / objectsPerPage)}
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default P2P
